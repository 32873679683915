<template>
  <FormFieldMultiCriterion
    :id="fieldId"
    :initialValue="criterion.languages ? [...criterion.languages] : undefined"
    :options
    :disabled="readonly || criterion.isReadOnly"
  />
</template>

<script setup lang="ts">
import { TypedCriterionFieldProps } from "@/components/selection/SelectionCriterionForm.types";
import texts from "@/utils/texts";
import { fields } from "@/utils/miscellaneous";
import { SelectionCriterionDto } from "@/lib/eduConfigurationServiceClient";
import FormFieldMultiCriterion from "@/components/selection/type/components/FormFieldMultiCriterion.vue";

const props = defineProps<TypedCriterionFieldProps>();

const fieldId = fields<SelectionCriterionDto>().languages;

const options = props.context.languages.map((language) => ({
  id: language,
  label: texts.enums.culture[language],
}));
</script>
