import { SelectionCriterionDtoType } from "@/lib/eduConfigurationServiceClient";

export const orderedCriterionTypes = [
  SelectionCriterionDtoType.Unknown,
  SelectionCriterionDtoType.ShowedInterest,
  SelectionCriterionDtoType.IsRegisteredForActivity,
  SelectionCriterionDtoType.IsRegisteredForSession,
  SelectionCriterionDtoType.IsNotRegisteredForActivity,
  SelectionCriterionDtoType.HasVisitedActivity,
  SelectionCriterionDtoType.HasNotVisitedActivity,
  SelectionCriterionDtoType.NotSubmittedSurveyForVisitedActivity,
  SelectionCriterionDtoType.IsRegisteredForTrialDay,
  SelectionCriterionDtoType.HasRequestedBrochure,
  SelectionCriterionDtoType.KeepMeInformed,
  SelectionCriterionDtoType.ThirdPartyProspectSource,
  SelectionCriterionDtoType.IsInTargetAudience,
  SelectionCriterionDtoType.IsNotTargetAudience,
  SelectionCriterionDtoType.HasPreEducation,
  SelectionCriterionDtoType.HasLanguage,
  SelectionCriterionDtoType.HasGivenAnswer,
  SelectionCriterionDtoType.WantsToReceiveMarketingEmail,
  SelectionCriterionDtoType.NotReceivedMailing,
  SelectionCriterionDtoType.HasApplied,
  SelectionCriterionDtoType.HasNotApplied,
  SelectionCriterionDtoType.IsNotEnrolled,
  // Deprecated criteria
  SelectionCriterionDtoType.ShowedInterestInCurrentYear,
  SelectionCriterionDtoType.IsInterestedInStudyProgram,
] as const;
