<template>
  <SlideOver
    v-model:visible="slideOverOpen"
    :title="componentTexts.title"
    :subTitle="componentTexts.subTitle"
  >
    <!-- Classes dupe of SlideOverFormWrapper root element-->
    <div class="flex h-full flex-col gap-8 px-4 sm:px-6">
      <div class="flex flex-col gap-4">
        <SectionHeading
          :title="componentTexts.basicInformation"
          :divider="false"
        />
        <DescriptionList>
          <DescriptionListItem
            :name="texts.models.contactMoment.contactMomentType"
            :data-testid="testIds.contact.journey.slideout.contactMomentType"
          >
            <SingleValue
              :value="texts.enums.contactMomentType[contactMoment.type]"
            />
          </DescriptionListItem>
          <DescriptionListItem
            v-if="contactMoment.activityName"
            :name="texts.models.contactMoment.activityName"
            :data-testid="testIds.contact.journey.slideout.activityName"
          >
            <SingleValue :value="contactMoment.activityName" />
          </DescriptionListItem>
          <DescriptionListItem
            v-if="contactMoment.mailingName"
            :name="texts.models.contactMoment.mailingName"
          >
            <SingleValue :value="contactMoment.mailingName" />
          </DescriptionListItem>
          <DescriptionListItem :name="texts.models.contactMoment.dateTime">
            <SingleValue
              :value="
                contactMoment.dateTime.toLocaleString(DateTime.DATETIME_MED)
              "
            />
          </DescriptionListItem>

          <DescriptionListItem
            v-if="contactMoment.type === ContactMomentType.Visit"
            :name="texts.models.contactMoment.location"
          >
            <SingleValue :value="contactMoment.locationName || '-'" />
          </DescriptionListItem>

          <DescriptionListItem
            v-if="contactMoment.type === ContactMomentType.Registration"
            :name="texts.models.contactMoment.registrationLink"
            :data-testid="testIds.contact.journey.slideout.registrationLink"
          >
            <SingleValue :value="contactMoment.registrationLinkName || '-'" />
          </DescriptionListItem>
          <DescriptionListItem
            v-if="contactMoment.type === ContactMomentType.Registration"
            :name="texts.models.contactMoment.ticketCode"
          >
            <SingleValue :value="contactMoment.ticketCode || '-'" />
          </DescriptionListItem>

          <DescriptionListItem
            v-if="contactMoment.type === ContactMomentType.BrochureRequest"
            :name="texts.models.contactMoment.brochureNames"
          >
            <SingleValue
              :value="contactMoment.brochureNames?.join('; ') || '-'"
            />
          </DescriptionListItem>

          <DescriptionListItem
            v-if="
              contactMoment.type === ContactMomentType.Enrollment ||
              contactMoment.type === ContactMomentType.Application
            "
            :name="texts.models.contactMoment.studyProgramName"
          >
            <SingleValue :value="contactMoment.studyProgramName || '-'" />
          </DescriptionListItem>
          <DescriptionListItem
            v-if="contactMoment.type === ContactMomentType.Enrollment"
            :name="texts.models.contactMoment.studyProgramStartsAt"
          >
            <SingleValue
              :value="
                contactMoment.studyProgramStartsAt
                  ? contactMoment.studyProgramStartsAt.toLocaleString(
                      DateTime.DATETIME_MED,
                    )
                  : '-'
              "
            />
          </DescriptionListItem>

          <DescriptionListItem
            v-if="contactMoment.type === ContactMomentType.SessionVisit"
            :name="texts.models.contactMoment.sessionName"
          >
            <SingleValue
              :value="
                contactMoment.sessionName +
                (contactMoment.sessionStartsAt
                  ? ' (' +
                    contactMoment.sessionStartsAt.toLocaleString(
                      DateTime.DATETIME_MED,
                    ) +
                    ')'
                  : '')
              "
            />
          </DescriptionListItem>
          <DescriptionListItem
            v-if="
              contactMoment.type === ContactMomentType.SessionVisit ||
              contactMoment.type === ContactMomentType.TrialDayRegistration
            "
            :name="texts.models.contactMoment.studyProgramNames"
          >
            <SingleValue
              :value="contactMoment.studyProgramNames?.join('; ') || '-'"
            />
          </DescriptionListItem>

          <DescriptionListItem
            v-if="contactMoment.type === ContactMomentType.ShortMessage"
            :name="texts.models.contactMoment.shortMessageText"
          >
            <SingleValue :value="contactMoment.messageText || ''" />
          </DescriptionListItem>

          <DescriptionListItem
            v-if="
              contactMoment.type === ContactMomentType.ThirdPartyProspectImport
            "
            :name="texts.models.contactMoment.sourceName"
          >
            <SingleValue :value="contactMoment.sourceName || '-'" />
          </DescriptionListItem>
        </DescriptionList>
      </div>

      <div v-if="contactMoment.type === ContactMomentType.TrialDayRegistration">
        <SectionHeading :title="componentTexts.actions" :divider="false" />
        <div class="flex flex-col items-start gap-2 sm:flex-row">
          <TextButton
            :label="componentTexts.buttons.editRegistration"
            :buttonElementType="ButtonElementType.button"
            :color="Color.Emerald"
            @click="editRegistration"
          />
        </div>
      </div>
      <div
        v-if="contactMoment.type === ContactMomentType.Registration"
        class="flex flex-col gap-4"
      >
        <SectionHeading :title="componentTexts.actions" :divider="false" />
        <div class="flex flex-col items-start gap-4">
          <TextButton
            :label="componentTexts.buttons.editRegistration"
            :buttonElementType="ButtonElementType.button"
            :color="Color.Emerald"
            @click="editRegistration"
          />

          <TextButton
            :label="componentTexts.buttons.resendRegistrationConfirmation"
            :loading="sendingRegistrationConfirmation"
            :buttonElementType="ButtonElementType.button"
            :disabled="!contactMoment.confirmationCanBeResent"
            :color="Color.Emerald"
            @click="resendRegistrationConfirmation"
          />
          <TextButton
            v-if="contactMoment.activityId"
            :label="componentTexts.buttons.addVisit"
            :buttonElementType="ButtonElementType.button"
            :color="Color.Emerald"
            @click="
              emit('add:visit', contactMoment.id, contactMoment.activityId);
              slideOverOpen = false;
            "
          />
        </div>
      </div>

      <div
        v-if="contactMoment.type === ContactMomentType.Application"
        class="flex flex-col gap-4"
      >
        <SectionHeading :title="componentTexts.actions" :divider="false" />
        <template v-if="cancellingApplication">
          <ConfirmDeleteDialogButtons
            :loading="cancelApplicationLoading"
            @confirm="cancelApplication"
            @cancel="() => (cancellingApplication = false)"
          ></ConfirmDeleteDialogButtons>
        </template>
        <template v-else>
          <div class="flex flex-col items-start gap-2 sm:flex-row">
            <template v-if="applicationCanBeEdited">
              <Button
                :loading="applicationStatusLoading"
                :label="componentTexts.application.edit"
                :buttonElementType="ButtonElementType.button"
                @click="editApplication"
              />
            </template>
            <template v-else>
              <ButtonDelete
                :loading="applicationStatusLoading"
                :label="componentTexts.application.cancel.title"
                class="md:col-start-2"
                @click="() => (cancellingApplication = true)"
              />
            </template>
          </div>
        </template>
      </div>

      <div
        v-if="contactMoment.type === ContactMomentType.Enrollment"
        class="flex flex-col gap-4"
      >
        <SectionHeading :title="componentTexts.actions" :divider="false" />
        <template v-if="deletingEnrollment">
          <ConfirmDeleteDialogButtons
            :loading="deleteEnrollmentLoading"
            @confirm="deleteEnrollment"
            @cancel="() => (deletingEnrollment = false)"
          ></ConfirmDeleteDialogButtons>
        </template>
        <template v-else>
          <div class="flex flex-col items-start gap-2 sm:flex-row">
            <ButtonDelete
              class="md:col-start-2"
              @click="() => (deletingEnrollment = true)"
            />
          </div>
        </template>
      </div>
    </div>
  </SlideOver>
</template>

<script setup lang="ts">
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import Button from "@/components/common/button/Button.vue";
import { ButtonElementType } from "@/components/common/button/Button.types";
import { ContactMoment } from "@/models/contact-moment";
import { computed, ref, watch, onMounted } from "vue";
import DescriptionList from "@/components/common/description-list/DescriptionList.vue";
import DescriptionListItem from "@/components/common/description-list/DescriptionListItem.vue";
import SingleValue from "@/components/common/description-list/DescriptionListSingleValue.vue";
import SectionHeading from "@/components/common/section/SectionHeading.vue";
import { ContactMomentType } from "@/models/contact-moment";
import { sendRegistrationConfirmation } from "@/services/contact.service";
import Notify from "@/utils/notify";
import logger from "@/plugins/logger";
import { DateTime } from "luxon";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import ConfirmDeleteDialogButtons from "@/components/common/button/ConfirmDeleteDialogButtons.vue";
import ButtonDelete from "@/components/common/button/ButtonDelete.vue";
import { testIds } from "@/utils/testing";
import texts from "@/utils/texts";
import TextButton from "@/components/common/button/TextButton.vue";
import { Color } from "@/enums";

const componentTexts = texts.navigationItems.contact.contactMoments.details;

const props = defineProps<{
  contactMoment: ContactMoment;
  viewingDetails: boolean;
}>();

const emit = defineEmits<{
  (e: "update:viewingDetails", value: boolean): void;
  (e: "deleted:enrollment"): void;
  (e: "deleted:application"): void;
  (e: "add:visit", contactMomentId: string, activityId: string): void;
}>();

// Implement v-model:viewingDetails with writable computed
// https://vuejs.org/guide/components/events.html#usage-with-v-model
const slideOverOpen = computed({
  get() {
    return props.viewingDetails;
  },
  set(value) {
    emit("update:viewingDetails", value);
  },
});

const applicationCanBeEdited = ref<boolean>(false);
const applicationStatusLoading = ref<boolean>(false);

const checkApplicationCanBeEdited = async () => {
  applicationStatusLoading.value = true;
  try {
    applicationCanBeEdited.value =
      await eduConfigurationServiceClient.applicationHasFormEntry(
        props.contactMoment.id,
      );
  } catch (error) {
    logger.error(error);
    applicationCanBeEdited.value = false;
  } finally {
    applicationStatusLoading.value = false;
  }
};

// Check applicationCanBeEdited when the component is mounted and whenever slideOverOpen changes
const updateApplicationStatus = async () => {
  if (props.contactMoment.type === ContactMomentType.Application) {
    await checkApplicationCanBeEdited();
  }
};

onMounted(() => {
  updateApplicationStatus();
});

watch(slideOverOpen, (isOpened) => {
  if (isOpened) {
    updateApplicationStatus();
  }
});

const editRegistration = () => {
  if (props.contactMoment && props.contactMoment.editLink) {
    window.open(props.contactMoment.editLink, "_blank");
  }
};

const editApplication = () => {
  if (props.contactMoment && props.contactMoment.editLink) {
    window.open(props.contactMoment.editLink, "_blank");
  }
};

const cancelApplicationLoading = ref<boolean>(false);
const cancellingApplication = ref<boolean>(false);

const cancelApplication = () => {
  cancelApplicationLoading.value = true;

  eduConfigurationServiceClient
    .cancelApplication(props.contactMoment.id)
    .then(() => {
      emit("deleted:application");
      slideOverOpen.value = false;
      Notify.success(componentTexts.application.cancel.result.success);
    })
    .catch((error) => {
      logger.error(error);
      Notify.failure(componentTexts.application.cancel.result.failure);
    })
    .finally(() => {
      cancellingApplication.value = false;
      cancelApplicationLoading.value = false;
    });
};

const deleteEnrollmentLoading = ref<boolean>(false);
const deletingEnrollment = ref<boolean>(false);

const deleteEnrollment = () => {
  deleteEnrollmentLoading.value = true;

  eduConfigurationServiceClient
    .deleteEnrollment(props.contactMoment.id)
    .then(() => {
      emit("deleted:enrollment");
      slideOverOpen.value = false;
      Notify.success(componentTexts.deleteEnrollmentResult.success);
    })
    .catch((error) => {
      logger.error(error);
      Notify.failure(componentTexts.deleteEnrollmentResult.failure);
    })
    .finally(() => {
      deletingEnrollment.value = false;
      deleteEnrollmentLoading.value = false;
    });
};

const sendingRegistrationConfirmation = ref(false);

const resendRegistrationConfirmation = () => {
  sendingRegistrationConfirmation.value = true;

  if (!props.contactMoment?.id) {
    logger.error(
      new Error(
        "Contact moment has no Id, can't resend registration confirmation",
      ),
    );

    Notify.failure(componentTexts.registrationConfirmation.failure);
    return;
  }

  sendRegistrationConfirmation(props.contactMoment.id)
    .then(() => {
      Notify.success(componentTexts.registrationConfirmation.success);
    })
    .catch((error) => {
      logger.error(error);
      Notify.failure(componentTexts.registrationConfirmation.failure);
    })
    .finally(() => {
      sendingRegistrationConfirmation.value = false;
    });
};
</script>
