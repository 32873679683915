<template>
  <Page :loading="loading" :error="error">
    <template v-if="!!contact">
      <Section class="flex flex-col gap-3">
        <RouterLinkAuthWithIcon
          :data-testId="testIds.backToOverview"
          :to="{ name: RouteNames.CONTACTS }"
          icon="arrow_left_alt"
          :text="texts.actions.backToOverview"
        >
        </RouterLinkAuthWithIcon>
        <PageHeading
          icon="account_circle"
          :title="fullName"
          :badgeType="badgeType"
          :badgeLabel="badgeLabel"
          :badgeIcon="badgeIcon"
        >
          <template v-slot:actions>
            <DropdownWrapper :origin="DropdownOrigin.TopRight" class="h-6">
              <template v-slot:button>
                <MenuButton :data-testid="testIds.actions">
                  <Icon
                    :size="IconSize.lg"
                    icon="more_vert"
                    :color="Color.Emerald"
                  >
                  </Icon>
                </MenuButton>
              </template>
              <template v-slot:items>
                <DropdownItem
                  v-if="
                    contact.anonymization == AnonymizationState.NotAnonymized
                  "
                  :data-testid="testIds.action.edit"
                  :label="texts.navigationItems.contact.actions.anonymize"
                  @click="openAnonymizationPanel"
                ></DropdownItem>
              </template>
            </DropdownWrapper>
          </template>
        </PageHeading>
      </Section>
      <div class="flex flex-col gap-6 sm:flex-row sm:items-start">
        <ContactDetails
          :contact="contact"
          class="flex-1"
          @editProfile="editSlideOverOpen = true"
        />
        <ContactJourney
          v-if="contactMoments"
          :contactMoments="contactMoments"
          class="flex-1"
          @view:details="handleViewDetails"
          @add:contactMoment="slideOverAddEnrollmentOpen = true"
        ></ContactJourney>
      </div>
    </template>

    <SlideOver
      v-model:visible="anomymizationSlideOverOpen"
      :title="texts.navigationItems.contact.anonymization.title"
      :subTitle="texts.navigationItems.contact.anonymization.subtitle"
    >
      <ContactAnonymize
        :contact="contact!"
        @anonymized="
          anomymizationSlideOverOpen = false;
          loadContact();
        "
      />
    </SlideOver>

    <ContactEdit
      v-if="contact && editSlideOverOpen"
      v-model:editProfile="editSlideOverOpen"
      :contact="contact"
      @update:editProfile="loadContact"
    />

    <ContactMomentDetails
      v-if="currentContactMoment"
      v-model:viewingDetails="viewingDetails"
      :contactMoment="currentContactMoment"
      @deleted:enrollment="loadContactMomentList"
      @deleted:application="loadContactMomentList"
      @add:visit="openAddVisitModal"
    />
    <SlideOver
      v-model:visible="slideOverAddEnrollmentOpen"
      :title="componentTexts.addEnrollment.title"
      :subTitle="componentTexts.addEnrollment.subTitle"
    >
      <ContactMomentAddEnrollment
        v-if="contact"
        :prospect="contact"
        @added:enrollment="
          slideOverAddEnrollmentOpen = false;
          loadContactMomentList();
        "
      >
        <ButtonSubmit></ButtonSubmit>
        <ButtonCancel @click="slideOverAddEnrollmentOpen = false"></ButtonCancel
      ></ContactMomentAddEnrollment>
    </SlideOver>
  </Page>

  <Modal
    v-model:visible="addVisitModalVisible"
    :title="componentTexts.addVisit.title"
    :description="componentTexts.addVisit.subtitle"
    :type="ModalType.success"
  >
    <template v-slot:content>
      <ContactMomentAddVisitForm
        v-if="activity"
        :activity="activity"
        @submit="submitAddVisit"
        @cancel="addVisitModalVisible = false"
      >
        <ButtonSubmit :loading="addingVisit" :size="ButtonSize.sm" />
        <ButtonCancel :size="ButtonSize.sm" @click="cancelAddVisit" />
      </ContactMomentAddVisitForm>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { ref } from "vue";
import Page from "@/components/common/page/Page.vue";
import { getContact } from "@/services/contact.service";
import { Prospect } from "@/models/contact";
import Section from "@/components/common/section/Section.vue";
import RouterLinkAuthWithIcon from "@/components/router/router-link-auth/RouterLinkAuthWithIcon.vue";
import { RouteNames } from "@/router/routeNames";
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import { computed } from "vue";
import { testIds } from "@/utils/testing";
import logger from "@/plugins/logger";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import ContactAnonymize from "./ContactAnonymize.vue";
import ContactDetails from "@/views/contacts/ContactDetails.vue";
import ContactJourney from "@/views/contacts/ContactJourney.vue";
import ContactEdit from "@/views/contacts/ContactEdit.vue";
import ContactMomentDetails from "@/views/contacts/ContactMomentDetails.vue";
import { ContactMoment, ContactMomentType } from "@/models/contact-moment";
import ContactMomentAddEnrollment from "@/views/contacts/ContactMomentAddEnrollment.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import { BadgeType } from "@/components/common/badge/BadgeProps";
import Icon from "@/components/common/icon/Icon.vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import { getContactMoments } from "@/services/contact-moment.service";
import { DropdownOrigin } from "@/components/common/dropdown/Dropdown.types";
import { Color } from "@/enums";
import { MenuButton } from "@headlessui/vue";
import DropdownWrapper from "@/components/common/dropdown/DropdownWrapper.vue";
import {
  ActivityDTO,
  AnonymizationState,
  HelpdeskCreateVisitDto,
} from "@/lib/eduConfigurationServiceClient";
import texts from "@/utils/texts";
import Modal from "@/components/common/modal/Modal.vue";
import ContactMomentAddVisitForm from "@/views/contacts/components/ContactMomentAddVisitForm.vue";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { ContactMomentAddVisitFormValues } from "@/views/contacts/components/ContactMomentAddVisitForm.types";
import Notify from "@/utils/notify";
import { ModalType } from "@/components/common/modal/Modal.types";
import { ButtonSize } from "@/components/common/button/Button.types";

const componentTexts = texts.navigationItems.contact.contactMoments;

const props = defineProps<{
  id: string;
}>();

const loading = ref<boolean>(true);
const error = ref<boolean>(false);
const contact = ref<Prospect | undefined>(undefined);
const fullName = computed<string>(
  () =>
    `${contact.value?.firstName} ${
      contact.value?.infix
        ? `${contact.value?.infix} ${contact.value?.lastName}`
        : contact.value?.lastName
    }`,
);

const anomymizationSlideOverOpen = ref<boolean>(false);
const editSlideOverOpen = ref<boolean>(false);
const openAnonymizationPanel = () => {
  anomymizationSlideOverOpen.value = true;
};

const loadContact = () => {
  getContact(props.id)
    .then((dto) => {
      contact.value = new Prospect(dto);
    })
    .catch((e) => {
      error.value = true;
      logger.error(e);
    });
};

const loadingContactMoments = ref<boolean>(true);
const errorLoadingContactMoments = ref<boolean>(false);
const contactMoments = ref<ContactMoment[]>();
const loadContactMomentList = () => {
  getContactMoments(props.id)
    .then((dtos) => {
      const mappedResults = dtos.map((dto) => new ContactMoment(dto));

      contactMoments.value = mappedResults;
    })
    .catch((e) => {
      logger.error(e);

      errorLoadingContactMoments.value = true;
    })
    .finally(() => {
      loadingContactMoments.value = false;
    });
};

initialize();
function initialize() {
  loading.value = true;
  Promise.all([loadContact(), loadContactMomentList()]).finally(() => {
    loading.value = false;
  });
}

const currentContactMoment = ref<ContactMoment>();
const viewingDetails = ref(false);
const handleViewDetails = (contactMoment: ContactMoment) => {
  currentContactMoment.value = contactMoment;
  viewingDetails.value = true;
};

const slideOverAddEnrollmentOpen = ref<boolean>(false);

const hasEnrollments = computed(() => {
  return contactMoments.value?.filter(
    (c) => c.type === ContactMomentType.Enrollment,
  ).length;
});

const hasApplications = computed(() => {
  return contactMoments.value?.filter(
    (c) => c.type === ContactMomentType.Application,
  ).length;
});

const badgeType = computed(() => {
  if (hasEnrollments.value) {
    return BadgeType.emerald;
  }
  if (hasApplications.value) {
    return BadgeType.lavender;
  }
  return undefined;
});

const badgeLabel = computed(() => {
  if (hasEnrollments.value) {
    return " Ingeschreven student";
  }
  if (hasApplications.value) {
    return " Aangemeld voor opleiding(en)";
  }
  return undefined;
});

const badgeIcon = computed(() => {
  if (hasEnrollments.value) {
    return "school";
  }
  if (hasApplications.value) {
    return "hotel_class";
  }
  return undefined;
});

const addVisitModalVisible = ref(false);
const activity = ref<ActivityDTO>();
const addVisitParams = ref<{
  activityId: string;
  contactMomentId: string;
}>();

const openAddVisitModal = async (
  contactMomentId: string,
  activityId: string,
) => {
  addVisitParams.value = {
    activityId,
    contactMomentId,
  };

  activity.value =
    await eduConfigurationServiceClient.getActivityById(activityId);
  addVisitModalVisible.value = true;
};

const addingVisit = ref(false);
const submitAddVisit = async (values: ContactMomentAddVisitFormValues) => {
  if (!contact.value) {
    throw new Error("Contact not found");
  }

  if (!addVisitParams.value) {
    throw new Error("Params not found");
  }

  try {
    addingVisit.value = true;
    await eduConfigurationServiceClient.addProspectVisit(
      contact.value.id,
      new HelpdeskCreateVisitDto({
        activityId: addVisitParams.value.activityId,
        visitDateTime: values.visitDateTime,
        locationId: values.activityLocationId,
        registrationId: addVisitParams.value.contactMomentId,
      }),
    );
    Notify.success(texts.notifications.create.success, [
      texts.models.activity.visit,
    ]);
    loadContactMomentList();
    addVisitModalVisible.value = false;
  } catch (e) {
    Notify.failure(texts.notifications.create.failure, [
      texts.models.activity.visit,
    ]);
    throw e;
  } finally {
    addingVisit.value = false;
  }
};

const cancelAddVisit = () => {
  addVisitModalVisible.value = false;
  addVisitParams.value = undefined;
};
</script>
