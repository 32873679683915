<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="visibleInternal">
    <Dialog class="relative z-slideOver" @close="close">
      <TransitionChild
        as="template"
        enter="ease-in-out duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in-out duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div :class="['fixed inset-0 bg-gray-600/60 transition-all']" />
      </TransitionChild>

      <div
        class="fixed inset-0 overflow-hidden"
        :data-testid="testIds.slideOver"
      >
        <div class="absolute inset-0 overflow-hidden">
          <div
            class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10"
          >
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <DialogPanel
                :class="[
                  'pointer-events-auto w-screen bg-white',
                  {
                    'max-w-lg': size === 'xs',
                    'max-w-xl': size === 'sm',
                    'max-w-2xl': size === 'md',
                    'max-w-4xl': size === 'lg',
                    'max-w-5xl': size === 'xl',
                  },
                ]"
              >
                <div
                  :class="['flex h-full flex-col overflow-y-scroll shadow-xl']"
                >
                  <div class="px-4 py-6 sm:px-6">
                    <div class="flex items-start justify-between space-x-3">
                      <DialogTitle>
                        <PageHeading>
                          <div class="flex flex-col gap-2">
                            <PageHeading data-testid="slideover-title">{{
                              title
                            }}</PageHeading>
                            <PageSubTitle
                              v-if="subTitle"
                              data-testid="slideover-subtitle"
                              >{{ subTitle }}</PageSubTitle
                            >
                          </div>
                        </PageHeading>
                      </DialogTitle>
                      <div class="flex h-7 items-center">
                        <button
                          v-if="!disabled"
                          type="button"
                          :class="[
                            'relative rounded-full focus:outline-none focus:ring-2 focus:ring-emerald-500/40 focus:ring-offset-2',
                            {
                              'text-gray-400 hover:text-gray-500': !disabled,
                            },
                          ]"
                          :data-testid="testIds.action.close"
                          :disabled
                          @click="close"
                        >
                          <span class="absolute -inset-2.5" />
                          <span class="sr-only">Close panel</span>
                          <Icon
                            icon="close"
                            :size="IconSize.md"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  <slot></slot>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { testIds } from "@/utils/testing";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { computed } from "vue";
import PageHeading from "../page-heading/PageHeading.vue";
import PageSubTitle from "../page-heading/PageSubTitle.vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import Icon from "@/components/common/icon/Icon.vue";
import { Size } from "@/enums";

const props = withDefaults(
  defineProps<{
    visible: boolean;
    title?: string;
    subTitle?: string;
    size?: Size;
    disabled?: boolean;
  }>(),
  {
    title: undefined,
    subTitle: undefined,
    size: "md",
    disabled: false,
  },
);

const emit = defineEmits<{
  (e: "update:visible", value: boolean): void;
  (e: "close", value: boolean): void;
}>();

const visibleInternal = computed({
  get: () => props.visible,
  set: (value) => emit("update:visible", value),
});

function close() {
  if (!props.disabled) {
    visibleInternal.value = false;
  }
  emit("close", !visibleInternal.value);
}
</script>
