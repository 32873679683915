<template>
  <PopoverWrapper :panelOrigin>
    <template v-slot:button>
      <PopoverButton
        :as="Button"
        :color="
          definition.filters.studyProgramFilter.studyProgramIds.length ||
          definition.filters.studyProgramFilter.activityIds.length
            ? Color.Lavender
            : Color.Gray
        "
        :size="ButtonSize.sm"
        :trailingIcon="'keyboard_arrow_down'"
        :disabled
        round
        :data-testid="testIds.dashboard.studyProgramFilter"
      >
        <template
          v-if="definition.filters.studyProgramFilter.studyProgramIds.length"
        >
          <span>{{ componentTexts.studyProgramsPicker.title }}</span>
          <Tag :size="TagSize.xs" :color="Color.Lavender" rounded>{{
            definition.filters.studyProgramFilter.studyProgramIds.length
          }}</Tag>
        </template>
        <template
          v-else-if="definition.filters.studyProgramFilter.activityIds.length"
        >
          <span>{{
            `${activity.name} (${activity.startDateTime.toLocaleString(
              DateTime.DATE_SHORT,
            )})`
          }}</span>
        </template>
        <template v-else>
          <span>{{ componentTexts.studyProgramsPicker.title }}</span>
          <Tag :size="TagSize.xs" :color="Color.DeepTeal" rounded dark>0</Tag>
        </template>
      </PopoverButton>
    </template>
    <template v-slot:panel="{ close }">
      <SelectionStudyProgramsFilterForm
        :studyPrograms="studyPrograms"
        :formValues="{
          studyProgramIds:
            definition.filters.studyProgramFilter.studyProgramIds,
          activityIds: definition.filters.studyProgramFilter.activityIds,
        }"
        :apply
        :activity
        @submit="
          (values) => {
            onSubmit(values);
            if (!apply) close();
          }
        "
        @cancel="close()"
      />
    </template>
  </PopoverWrapper>
</template>

<script setup lang="ts">
import {
  ISelectionDefinitionDto,
  LocalizedActivityDTO,
  LocalizedStudyProgramDTO,
  SelectionDefinitionDto,
} from "@/lib/eduConfigurationServiceClient";
import texts from "@/utils/texts";
import PopoverWrapper from "@/components/common/popover/PopoverWrapper.vue";
import { PanelOrigin } from "@/components/common/popover/PopoverWrapper.types";
import { Color } from "@/enums";
import { ButtonSize } from "@/components/common/button/Button.types";
import Button from "@/components/common/button/Button.vue";
import { PopoverButton } from "@headlessui/vue";
import { testIds } from "@/utils/testing";
import Tag from "@/components/common/tag/Tag.vue";
import { TagSize } from "@/components/common/tag/Tag.types";
import SelectionStudyProgramsFilterForm, {
  StudyProgramFilterFormValues,
} from "@/components/selection/filter/components/SelectionStudyProgramsFilterForm.vue";
import { DateTime } from "luxon";

withDefaults(
  defineProps<{
    studyPrograms: LocalizedStudyProgramDTO[];
    panelOrigin?: PanelOrigin;
    disabled?: boolean;
    apply?: boolean;
    activity?: LocalizedActivityDTO;
  }>(),
  {
    panelOrigin: PanelOrigin.TopLeft,
    disabled: false,
    apply: false,
    activity: undefined,
  },
);

const componentTexts = texts.navigationItems.dashboard;

const definition = defineModel<ISelectionDefinitionDto>({
  required: true,
});

const onSubmit = (values: StudyProgramFilterFormValues) => {
  definition.value = new SelectionDefinitionDto({
    ...definition.value,
    filters: {
      ...definition.value.filters,
      studyProgramFilter: {
        activityIds: values.activityIds,
        studyProgramIds: values.studyProgramIds,
      },
    },
  });
};
</script>
