<template>
  <Loader v-if="loading" data-testid="loading"></Loader>
  <ErrorComponent v-else-if="error" data-testid="error"></ErrorComponent>
  <div
    v-else
    :data-testid="testIds.studyProgramPicker.component"
    v-bind="$attrs"
    class="flex flex-col gap-4"
  >
    <div class="flex justify-between gap-3">
      <Search v-model="search" class="flex-1" />
      <div
        class="flex items-center gap-3 text-sm font-normal text-deepteal-500 underline"
      >
        <button
          class="text-deepteal-600 hover:text-deepteal-700"
          type="button"
          :data-testid="testIds.action.all"
          @click="updateAllSelected(true)"
        >
          {{ texts.actions.selectAll }}
        </button>
        <button
          class="text-deepteal-600 hover:text-deepteal-700"
          type="button"
          :data-testid="testIds.action.reset"
          @click="updateAllSelected(false)"
        >
          {{ texts.actions.reset }}
        </button>
      </div>
    </div>

    <div class="flex flex-wrap gap-1">
      <StudyProgramPickerFilter
        v-model="selectedMode"
        :placeholder="texts.models.studyProgramMode.title"
        :items="modes"
        class="flex-1"
      />
      <StudyProgramPickerFilter
        v-model="selectedLevel"
        :placeholder="texts.models.studyProgramLevel.title"
        :items="levels"
        class="flex-1"
      />
      <StudyProgramPickerFilter
        v-model="selectedLanguage"
        :placeholder="texts.models.studyProgramLanguage.title"
        :items="settings.languages"
        class="flex-1"
      />
      <StudyProgramPickerFilter
        v-model="selectedLocation"
        :placeholder="texts.models.studyProgramLocation.title"
        :items="locations"
        class="flex-1"
        :origin="DropdownOrigin.TopRight"
      />
      <StudyProgramPickerFilter
        v-model="selectedAreaOfInterest"
        :placeholder="texts.models.studyProgramAreaOfInterest.title"
        :items="areasOfInterest"
        class="flex-4"
        :origin="DropdownOrigin.TopRight"
      />
    </div>
    <div class="flex flex-col gap-4">
      <StudyProgramPickerListItemSection
        v-for="(group, key) in filteredStudyProgramsByDepartment"
        :key="key"
        v-model="selectedIdsInternal"
        :title="key"
        :studyPrograms="group"
      />
      <template v-if="filteredStudyProgramsNoDepartment.length > 0">
        <StudyProgramPickerListItemSection
          v-model="selectedIdsInternal"
          :title="`${
            texts.generic.without
          } ${texts.models.studyProgramDepartment.title.toLowerCase()}`"
          :studyPrograms="filteredStudyProgramsNoDepartment"
        />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { DropdownOrigin } from "@/components/common/dropdown/Dropdown.types";
import ErrorComponent from "@/components/common/error/Error.vue";
import Loader from "@/components/common/loader/Loader.vue";
import Search from "@/components/common/search/Search.vue";
import { LocalizedStudyProgramDTO } from "@/lib/eduConfigurationServiceClient";
import Language from "@/models/language";
import { StudyProgramAreaOfInterest } from "@/models/study-program-area-of-interest";
import { StudyProgramLevel } from "@/models/study-program-level";
import { StudyProgramLocation } from "@/models/study-program-location";
import { StudyProgramMode } from "@/models/study-program-mode";
import logger from "@/plugins/logger";
import {
  getStudyProgramAreasOfInterest,
  getStudyProgramLevels,
  getStudyProgramLocations,
  getStudyProgramModes,
} from "@/services/study-program.service";
import { testIds } from "@/utils/testing";
import { computed, ref, shallowRef } from "vue";
import StudyProgramPickerFilter from "@/components/study-programs/study-program-picker/StudyProgramPickerFilter.vue";
import { groupBy, sort } from "@/utils/array";
import StudyProgramPickerListItemSection from "@/components/study-programs/study-program-picker/StudyProgramPickerListItemSection.vue";
import settings from "@/store/context/settings.context";
import texts from "@/utils/texts";

const props = defineProps<{
  studyPrograms: LocalizedStudyProgramDTO[];
  departmentId?: string;
  selectedIds?: Array<string>;
}>();

// Filters
const search = ref<string | undefined>(undefined);
const selectedAreaOfInterest = shallowRef<StudyProgramAreaOfInterest>();
const areasOfInterest = shallowRef<StudyProgramAreaOfInterest[]>();
const selectedMode = shallowRef<StudyProgramMode>();
const modes = shallowRef<StudyProgramMode[]>();
const selectedLevel = shallowRef<StudyProgramLevel>();
const levels = shallowRef<StudyProgramLevel[]>();
const locations = shallowRef<StudyProgramLocation[]>();
const selectedLocation = shallowRef<StudyProgramLocation>();

const filteredStudyProgramsByDepartment = computed(() =>
  sort(
    groupBy(
      filteredStudyPrograms.value,
      (studyProgram) => studyProgram.studyProgramDepartment?.name,
    ),
  ),
);

const filteredStudyProgramsNoDepartment = computed(() =>
  filteredStudyPrograms.value.filter(
    (studyProgram) => !studyProgram.studyProgramDepartment,
  ),
);

const loading = computed(() => loadingMetadata.value);
const error = computed(() => errorLoadingMetadata.value);

const selectedLanguage = shallowRef<Language>();

// Other metadata
const loadingMetadata = ref(true);
const errorLoadingMetadata = ref(false);
Promise.all([
  getStudyProgramAreasOfInterest(),
  getStudyProgramModes(),
  getStudyProgramLevels(),
  getStudyProgramLocations(),
])
  .then(([aoi, ms, lvs, ls]) => {
    areasOfInterest.value = aoi;
    modes.value = ms;
    levels.value = lvs;
    locations.value = ls;
  })
  .catch((e) => {
    errorLoadingMetadata.value = true;
    logger.log(e);
  })
  .finally(() => (loadingMetadata.value = false));

const filteredStudyPrograms = computed<LocalizedStudyProgramDTO[]>(() => {
  return props.studyPrograms
    .filter((studyProgram) =>
      search.value ? matchesFilter(studyProgram, search.value) : true,
    )
    .filter(
      (studyProgram) =>
        selectedAreaOfInterest.value === undefined ||
        studyProgram.areasOfInterest.some(
          (areasOfInterest) =>
            selectedAreaOfInterest.value?.id === areasOfInterest.id,
        ),
    )
    .filter(
      (studyProgram) =>
        selectedMode.value === undefined ||
        selectedMode.value.id === studyProgram.studyProgramMode?.id,
    )
    .filter(
      (studyProgram) =>
        selectedLevel.value === undefined ||
        selectedLevel.value.id === studyProgram.studyProgramLevel?.id,
    )
    .filter(
      (studyProgram) =>
        selectedLanguage.value === undefined ||
        selectedLanguage.value.name === studyProgram.programLanguage,
    )
    .filter(
      (studyProgram) =>
        selectedLocation.value === undefined ||
        studyProgram.studyProgramLocations.some(
          (location) => location.id === selectedLocation.value?.id,
        ),
    );
});

const selectedIdsInternal = defineModel<string[]>("selectedIds", {
  default: [],
});

const matchesFilter = (
  program: LocalizedStudyProgramDTO,
  search: string,
): boolean => {
  return (
    search === "" ||
    program.name.toLowerCase().includes(search.toLowerCase()) ||
    (program.externalReference !== undefined &&
      program.externalReference.toLowerCase().includes(search.toLowerCase()))
  );
};

const updateAllSelected = (value: boolean) => {
  const studyPrograms = filteredStudyPrograms.value.map((sp) => sp.id);

  selectedIdsInternal.value = selectedIdsInternal.value
    .filter((id) => !studyPrograms.some((sp) => sp === id))
    .concat(value ? studyPrograms : []);
};
</script>
