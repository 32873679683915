<template>
  <div class="flex flex-col gap-8">
    <div class="flex flex-col gap-4">
      <SectionHeading
        :title="
          texts.navigationItems.mailings.statistics.basicDataSection.title
        "
        :divider="false"
      />
      <DescriptionGrid>
        <GridItem :name="texts.models.mailing.type">
          <SingleValue
            :value="data.type ? texts.enums.mailingType[data.type] : ''"
          />
        </GridItem>
        <GridItem :name="texts.models.mailing.locale">
          <SingleValue :value="data.localeName" />
        </GridItem>
        <GridItem :name="texts.models.mailing.activity">
          <SingleValue :value="data.activityName || ''" />
        </GridItem>
        <GridItem :name="texts.models.mailing.subject">
          <SingleValue :value="data.subject || ''" />
        </GridItem>
        <GridItem :name="texts.models.mailing.fromEmailAddress">
          <SingleValue :value="data.fromEmailAddress || ''" />
        </GridItem>
        <GridItem :name="texts.models.mailing.fromName">
          <SingleValue :value="data.fromName || ''"></SingleValue>
        </GridItem>
        <GridItem :name="texts.models.mailing.sentOn">
          <SingleValue
            :value="
              data.sentDateTime
                ? data.sentDateTime.toLocaleString(DateTime.DATETIME_MED)
                : ''
            "
          />
        </GridItem>
        <GridItem :name="texts.models.mailing.template">
          <MailingPreviewButton
            :label="
              texts.navigationItems.mailings.statistics.basicDataSection
                .mailingContentBtn
            "
            :mailingContent="data.mailingContent"
            class="mt-2 justify-self-start"
          />
        </GridItem>
      </DescriptionGrid>
    </div>
    <Divider />
    <div class="flex flex-col gap-4">
      <SectionHeading
        :title="
          texts.navigationItems.mailings.statistics.statisticsSection.title
        "
        :divider="false"
      />

      <Loader v-if="loadingStatistics"></Loader>
      <ErrorComponent v-else-if="errorLoadingStatistics"></ErrorComponent>
      <div v-else class="space-y-5" data-testid="statistics">
        <StatsGrid :statsItems="statsItems"></StatsGrid>
        <Table>
          <template v-slot:header>
            <TableRow>
              <TableHeader>{{
                texts.navigationItems.mailings.statistics.statisticsSection
                  .mailResponseStatsItems.status
              }}</TableHeader>
              <TableHeader>{{
                texts.navigationItems.mailings.statistics.statisticsSection
                  .mailResponseStatsItems.number
              }}</TableHeader>
              <TableHeader>{{
                texts.navigationItems.mailings.statistics.statisticsSection
                  .mailResponseStatsItems.percentage
              }}</TableHeader>
            </TableRow>
          </template>

          <TableRow
            v-for="(items, i) in mailResponseStatsItems"
            :key="items.title"
            :data-testid="'statistic-' + i"
          >
            <TableColumn accent>{{ items.title }}</TableColumn>
            <TableColumn data-testid="number">{{ items.number }}</TableColumn>
            <TableColumn data-testid="percentage">
              <span v-if="isNaN(items.percentage)">--</span>
              <span v-else> {{ items.percentage }}%</span>
            </TableColumn>
          </TableRow>
        </Table>
      </div>
    </div>
    <div
      v-if="data.type === MailingType.ActivityInvite"
      class="flex flex-col gap-4"
    >
      <SectionHeading
        :title="
          texts.navigationItems.mailings.statistics.conversionSection.title
        "
        :divider="false"
      />
      <StatsGrid :statsItems="registrationStatsItems"></StatsGrid>
    </div>
    <Divider />
    <div class="flex flex-col gap-4">
      <SectionHeading
        :title="
          texts.navigationItems.mailings.statistics.selectionSection.title
        "
        :divider="false"
      />
      <Async :state="state">
        <template v-slot:failure>
          <ErrorComponent />
        </template>
        <template v-slot:loading>
          <Loader />
        </template>
        <template v-slot>
          <SelectionDefinition
            v-if="selectionContext && data.selectionDefinition"
            :modelValue="data.selectionDefinition"
            :context="selectionContext"
            :activity="data.activity"
            readonly
          ></SelectionDefinition>
        </template>
      </Async>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, shallowRef } from "vue";
import { MailResponseStatus } from "@/enums";
import texts from "@/utils/texts";

// Components
import SectionHeading from "@/components/common/section/SectionHeading.vue";
import DescriptionGrid from "@/components/common/description-grid/DescriptionGrid.vue";
import GridItem from "@/components/common/description-grid/DescriptionGridItem.vue";
import SingleValue from "@/components/common/description-grid/DescriptionGridSingleValue.vue";
import StatsGrid from "@/components/common/stats-grid/StatsGrid.vue";
import Table from "@/components/common/table/Table.vue";
import TableRow from "@/components/common/table/TableRow.vue";
import TableHeader from "@/components/common/table/TableHeader.vue";
import TableColumn from "@/components/common/table/TableColumn.vue";
import MailingPreviewButton from "@/views/mailings/mailing/components/MailingPreviewButton.vue";
import Loader from "@/components/common/loader/Loader.vue";
import {
  MailingStatisticsBasicData,
  MailingStatsItem,
} from "./MailingStatistics.types";
import { StatsGridItem } from "@/components/common/stats-grid/StatsGrid.types";
import { getMailingStatistics } from "@/services/mailings.service";
import ErrorComponent from "@/components/common/error/Error.vue";
import logger from "@/plugins/logger";
import { getPercentage } from "@/utils/math";
import { MailingType } from "@/enums";
import { useAsyncState } from "@/components/common/async/Async.types";
import { loadCriterionFieldContext } from "@/components/selection/SelectionCriterionForm.context";
import SelectionDefinition from "@/components/selection/SelectionDefinition.vue";
import Async from "@/components/common/async/Async.vue";
import Divider from "@/components/common/divider/Divider.vue";
import { DateTime } from "luxon";

const props = defineProps<{
  mailingId: string;
  data: MailingStatisticsBasicData;
}>();

const {
  state,
  handler,
  response: selectionContext,
} = useAsyncState(() => loadCriterionFieldContext(props.data.activityId));
handler();

const statsItems = shallowRef<StatsGridItem[]>([]);
const mailResponseStatsItems = shallowRef<MailingStatsItem[]>([]);
const registrationStatsItems = shallowRef<StatsGridItem[]>([]);

const loadingStatistics = ref(true);
const errorLoadingStatistics = ref(false);
getMailingStatistics(props.mailingId)
  .then((stats) => {
    statsItems.value = [
      {
        title:
          texts.navigationItems.mailings.statistics.statisticsSection.statsItems
            .addressed,
        number: stats.total,
        icon: "mail",
      },
      {
        title:
          texts.navigationItems.mailings.statistics.statisticsSection.statsItems
            .opened,
        number: getPercentage(stats.opens, stats.total),
        numberSuffix: "%",
        icon: "drafts",
      },
      {
        title:
          texts.navigationItems.mailings.statistics.statisticsSection.statsItems
            .clicked,
        number: getPercentage(stats.clicks, stats.total),
        numberSuffix: "%",
        icon: "web_traffic",
      },
    ];

    mailResponseStatsItems.value = [
      {
        title: texts.enums.mailResponseStatus[MailResponseStatus.Delivered],
        number: stats.sent,
        percentage: getPercentage(stats.sent, stats.total),
      },
      {
        title: texts.enums.mailResponseStatus[MailResponseStatus.HardBounce],
        number: stats.hardBounces,
        percentage: getPercentage(stats.hardBounces, stats.total),
      },
      {
        title: texts.enums.mailResponseStatus[MailResponseStatus.SoftBounce],
        number: stats.softBounces,
        percentage: getPercentage(stats.softBounces, stats.total),
      },
      {
        title: texts.enums.mailResponseStatus[MailResponseStatus.Rejected],
        number: stats.rejects,
        percentage: getPercentage(stats.rejects, stats.total),
      },
      {
        title: texts.enums.mailResponseStatus[MailResponseStatus.Unknown],
        number: stats.unknown,
        percentage: getPercentage(stats.unknown, stats.total),
      },
    ];

    if (props.data.type === MailingType.ActivityInvite) {
      registrationStatsItems.value = [
        {
          title:
            texts.navigationItems.mailings.statistics.conversionSection
              .registrationStatsItems.registrants,
          number: stats.registrations,
          icon: "group",
        },
        {
          title:
            texts.navigationItems.mailings.statistics.conversionSection
              .registrationStatsItems.conversion,
          number: getPercentage(stats.registrations, stats.total),
          numberSuffix: "%",
          icon: "receipt",
        },
      ];
    }
  })
  .catch((e) => {
    logger.error(e);
    errorLoadingStatistics.value = true;
  })
  .finally(() => (loadingStatistics.value = false));
</script>
