import { MailingType, Color } from "../enums";
import colors from "../../colors.json";

export const DEFAULT_COLOR = Color.DeepTeal;
export const DEFAULT_FOREGROUND_COLOR = Color.White;

// These functions generate a TailwindCSS-proof color given an enum value
// These classes need to be added to the safelist in tailwind.config.js to avoid purging

export function getBgColor(color: Color = DEFAULT_COLOR, light = false) {
  if (color === Color.White) return "bg-white";
  if (color === Color.Black) return "bg-black";
  if (color === Color.Gray) light = true;

  return light ? `bg-${color}-100` : `bg-${color}-500`;
}

export function getBgHoverColor(color: Color = DEFAULT_COLOR, light = false) {
  if (color === Color.White) return "hover:bg-gray-200";
  if (color === Color.Black) return "hover:bg-gray-500";
  if (color === Color.Gray) light = true;

  return light ? `hover:bg-${color}-200` : `hover:bg-${color}-400`;
}

export function getFocusRingColor(color: Color = DEFAULT_COLOR, light = false) {
  if (color === Color.White) return "focus:ring-gray-300";
  if (color === Color.Black) return "focus:ring-gray-400";
  if (color === Color.Gray) light = true;

  return light ? `focus:ring-${color}-300` : `focus:ring-${color}-400`;
}

export function getFocusBorderColor(
  color: Color = DEFAULT_COLOR,
  light = false,
) {
  if (color === Color.White) return "focus:border-gray-300";
  if (color === Color.Black) return "focus:border-gray-400";
  if (color === Color.Gray) light = true;

  return light ? `focus:border-${color}-300` : `focus:border-${color}-400`;
}

export function getTextColor(color: Color = DEFAULT_COLOR) {
  if (color === Color.White) return "text-white";
  if (color === Color.Black) return "text-black";
  return `text-${color}-600`;
}

export function getTextHoverColor(color: Color = DEFAULT_COLOR) {
  if (color === Color.White) return "hover:text-gray-600";
  if (color === Color.Black) return "hover:text-gray-700";
  return `hover:text-${color}-700`;
}

export function getTextDecorationColor(color: Color = DEFAULT_COLOR) {
  if (color === Color.White) return "decoration-white";
  if (color === Color.Black) return "decoration-black";
  return `decoration-${color}-600`;
}

export function getStrokeColor(color: Color = DEFAULT_COLOR) {
  if (color === Color.White) return "stroke-white";
  if (color === Color.Black) return "stroke-black";
  return `stroke-${color}-600`;
}

export function getStrokeHoverColor(color: Color = DEFAULT_COLOR) {
  if (color === Color.White) return "hover:stroke-gray-500";
  if (color === Color.Black) return "hover:stroke-gray-600";
  return `hover:stroke-${color}-600`;
}

export function getFromColor(color: Color = DEFAULT_COLOR) {
  if (color === Color.White) return "from-white";
  if (color === Color.Black) return "from-black";
  return `from-${color}-500`;
}

export function getForegroundColor(
  backGroundColor: Color = DEFAULT_COLOR,
  light: boolean = false,
) {
  return (
    colorBgToFgMap.get(backGroundColor) ??
    (light ? backGroundColor : DEFAULT_FOREGROUND_COLOR)
  );
}

type ColorShade =
  | "DEFAULT"
  | "50"
  | "100"
  | "200"
  | "300"
  | "400"
  | "500"
  | "600"
  | "700"
  | "800"
  | "900";

export function getCssColor(color: Color, shade?: ColorShade) {
  const libraryColor = colors[color];
  if (typeof libraryColor === "string") return libraryColor ?? undefined;
  return libraryColor[shade ?? "DEFAULT"] ?? undefined;
}

export function createTailwindColorSafelist() {
  const colors = Object.keys(Color).map((c) => c.toLowerCase()) as Color[];

  let result: string[] = [];

  result = result.concat(colors.map((c) => getBgColor(c)));
  result = result.concat(colors.map((c) => getBgColor(c, true)));
  result = result.concat(colors.map((c) => getBgHoverColor(c)));
  result = result.concat(colors.map((c) => getBgHoverColor(c, true)));
  result = result.concat(colors.map((c) => getFocusRingColor(c)));
  result = result.concat(colors.map((c) => getFocusRingColor(c, true)));
  result = result.concat(colors.map((c) => getFocusBorderColor(c)));
  result = result.concat(colors.map((c) => getFocusBorderColor(c, true)));
  result = result.concat(colors.map((c) => getTextColor(c)));
  result = result.concat(colors.map((c) => getTextHoverColor(c)));
  result = result.concat(colors.map((c) => getTextDecorationColor(c)));
  result = result.concat(colors.map((c) => getStrokeColor(c)));
  result = result.concat(colors.map((c) => getStrokeHoverColor(c)));
  result = result.concat(colors.map((c) => getFromColor(c)));
  result = result.concat(colors.map((c) => getFocusBorderColor(c)));

  return result;
}

const colorBgToFgMap = new Map<Color, Color>([
  [Color.Gray, DEFAULT_COLOR],
  [Color.White, Color.Gray],
  [Color.Transparent, DEFAULT_COLOR],
]);

// This function generates a CSS-proof color given an GUID
// It generates a hash of the GUID and with it an UX-friendly color-value

export function getGuidColor(guid: string): string {
  const hash = guid.split("").reduce((acc, val) => {
    acc = (acc << 5) - acc + val.charCodeAt(0);
    return acc & acc;
  }, 0);

  const hue = Math.abs(hash) % 360;
  const saturation = 60 + (Math.abs(hash) % 20);
  const lightness = 70 - (Math.abs(hash) % 20);
  const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;

  return color;
}

export const getTypeColorHEX = (type: MailingType) => {
  if (type === MailingType.Activity) {
    return colors.gray[200];
  } else if (type === MailingType.ActivityReminder) {
    return colors.lavender.DEFAULT;
  } else if (type === MailingType.ActivityInvite) {
    return colors.sunflower.DEFAULT;
  } else if (type === MailingType.ActivityVisitedSurvey) {
    return colors.fuchsia.DEFAULT;
  }
};
