<template>
  <SelectionDefinitionLayout>
    <template v-slot:header>
      <slot></slot>
    </template>
    <template v-if="!readonly && !nofilter" v-slot:filters>
      <SelectionPeriodFilter
        v-model="definition"
        :recruitmentYear="settings.recruitmentYear"
        :panelOrigin="PanelOrigin.TopRight"
        apply
      />
      <SelectionStudyProgramsFilter
        v-model="definition"
        :studyPrograms="settings.allStudyPrograms"
        :panelOrigin="PanelOrigin.TopRight"
        :activity="activity"
        apply
      />
    </template>
    <template v-slot:criteria>
      <template
        v-for="criterion in definition.criteria.concat(newCriterion ?? [])"
        :key="criterion"
      >
        <div class="relative flex items-center">
          <SelectionDefinitionLegendIcon :isNew="criterion === newCriterion" />
          <SelectionCriterionForm
            class="flex-1"
            :criterion="criterion"
            :context="context"
            :filters="definition.filters"
            :readonly="readonly || criterion.isReadOnly"
            :activity="activity"
            @submit="(value) => submitCriterion(criterion, value)"
            @remove="removeCriterion(criterion)"
          />
        </div>
      </template>
    </template>
    <template v-if="!readonly" v-slot:actions>
      <div class="relative flex items-center">
        <SelectionDefinitionLegendIcon :isNew="!newCriterion" />
        <SelectionDefinitionAddCriterion
          v-if="!newCriterion"
          :exclude="exclude"
          @add="addNewCriterion"
        />
      </div>
    </template>
  </SelectionDefinitionLayout>
</template>

<script setup lang="ts">
import SelectionCriterionForm from "@/components/selection/SelectionCriterionForm.vue";
import { ref } from "vue";
import { CriterionFieldContext } from "@/components/selection/SelectionCriterionForm.context";
import {
  ISelectionCriterionDto,
  LocalizedActivityDTO,
  SelectionCriterionDto,
  SelectionCriterionDtoType,
  SelectionDefinitionDto,
} from "@/lib/eduConfigurationServiceClient";
import SelectionDefinitionLayout from "@/components/selection/components/SelectionDefinitionLayout.vue";
import SelectionDefinitionLegendIcon from "@/components/selection/components/SelectionDefinitionLegendIcon.vue";
import { criteriaSortFn } from "@/components/selection/SelectionCriterionForm.types";
import SelectionDefinitionAddCriterion from "@/components/selection/components/SelectionDefinitionAddCriterion.vue";
import SelectionPeriodFilter from "@/components/selection/filter/SelectionPeriodFilter.vue";
import SelectionStudyProgramsFilter from "@/components/selection/filter/SelectionStudyProgramsFilter.vue";
import settings from "@/store/context/settings.context";
import { PanelOrigin } from "@/components/common/popover/PopoverWrapper.types";

defineProps<{
  context: CriterionFieldContext;
  exclude?: SelectionCriterionDtoType[];
  readonly?: boolean;
  nofilter?: boolean;
  activity?: LocalizedActivityDTO;
}>();

const definition = defineModel<SelectionDefinitionDto>({ required: true });

const newCriterion = ref<SelectionCriterionDto>();

const addNewCriterion = (type: string | undefined) => {
  if (!type) return;

  const criterion = new SelectionCriterionDto({
    type: type as SelectionCriterionDtoType,
    isReadOnly: false,
  });

  newCriterion.value = criterion;
};

const submitCriterion = (
  criterion: SelectionCriterionDto,
  submitted: ISelectionCriterionDto,
) => {
  // We need to keep the reference with the criterion instance intact
  // To avoid the vue reactivity system from losing the reference
  // so we map all submitted properties to the existing criterion instance
  criterion.init(submitted);

  updateDefinitionCriteria([
    ...definition.value.criteria.filter((c) => c !== criterion),
    criterion,
  ]);

  if (criterion === newCriterion.value) {
    newCriterion.value = undefined;
  }
};

const removeCriterion = (criterion: SelectionCriterionDto) => {
  const index = definition.value.criteria.findIndex((c) => c === criterion);
  if (index >= 0) {
    updateDefinitionCriteria(
      definition.value.criteria.filter((c) => c !== criterion),
    );
  }

  if (criterion === newCriterion.value) {
    newCriterion.value = undefined;
  }
};

function updateDefinitionCriteria(criteria: SelectionCriterionDto[]) {
  // We need to update the definition on object level to trigger v-model update event
  // and keep the criteria sorted in a consistent way
  definition.value = new SelectionDefinitionDto({
    ...definition.value,
    criteria: criteria.toSorted(criteriaSortFn),
  });
}

updateDefinitionCriteria(definition.value.criteria);
</script>
