<template>
  <div class="flex flex-col gap-2">
    <div
      v-if="selectedOptions.length"
      class="flex flex-wrap items-center gap-1.5"
    >
      <TagRemovable
        v-for="option in selectedOptions"
        :key="option.id"
        :disabled
        @remove="removeOption(option.id)"
      >
        {{ option.label }}
      </TagRemovable>
      <button
        v-if="selectedOptions.length < options.length && !disabled"
        type="button"
        @click="showCombobox = true"
      >
        <Icon
          icon="add"
          :size="IconSize.sm"
          :color="Color.Lavender"
          clickable
        />
      </button>
    </div>
    <Combobox
      v-if="showCombobox"
      v-model="ids"
      :options
      :formatOptionDisplayString
      :searchFilter
      :valid
      hideTags
      :color="Color.Lavender"
      :focus="focusCombobox"
      @focusout="onFocusOut"
    ></Combobox>
  </div>
</template>

<script
  setup
  lang="ts"
  generic="OptionType extends { id: string; label: string }"
>
import Combobox from "@/components/common/combobox/Combobox.vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import Icon from "@/components/common/icon/Icon.vue";
import TagRemovable from "@/components/common/tag/TagRemovable.vue";
import { Color } from "@/enums";
import { computed, ref, watch } from "vue";

const props = withDefaults(
  defineProps<{
    options: OptionType[];
    searchFilter?: (option: OptionType, query: string) => boolean;
    formatOptionDisplayString?: (option: OptionType) => string;
    valid?: boolean;
    disabled?: boolean;
  }>(),
  {
    searchFilter: (option: OptionType, query: string) =>
      !!option.label?.toLowerCase().includes(query.toLowerCase()),
    formatOptionDisplayString: (option: OptionType) => option.label,
    valid: true,
    disabled: false,
  },
);

const ids = defineModel<string[] | undefined>();

const selectedOptions = computed(() =>
  props.options
    .filter((option) => ids.value?.includes(option.id))
    .filter((option) => option !== undefined),
);

const removeOption = (optionId: string) => {
  const newIds = ids.value?.filter((id) => id !== optionId);
  ids.value = newIds?.length ? newIds : undefined;
};

const showCombobox = ref(false);
const focusCombobox = ref(false);

const onFocusOut = () => {
  focusCombobox.value = false;
  if (ids.value?.length) {
    showCombobox.value = false;
  }
};

watch(
  ids,
  () => {
    if (!ids.value?.length) {
      showCombobox.value = true;
      focusCombobox.value = true;
    }
  },
  { immediate: true },
);
</script>
