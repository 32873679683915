<template>
  <Card
    :color="criterion.isReadOnly ? Color.DeepTeal : Color.White"
    :size="CardSize.md"
    :flat="readonly"
  >
    <form
      class="flex h-full flex-col gap-2 text-sm text-deepteal-600"
      @submit="submitForm"
    >
      <SelectionCriterionHeader :criterion :readonly @remove="emit('remove')" />
      <template v-if="!!CriterionTypedComponent">
        <CriterionTypedComponent :criterion :context :filters :readonly />
      </template>
      <SelectionCriterionExplainer :criterion :filters :activity />
    </form>
  </Card>
</template>

<script setup lang="ts">
import { computed, FunctionalComponent, watch } from "vue";
import { useForm } from "vee-validate";
import { CriterionFieldContext } from "@/components/selection/SelectionCriterionForm.context";
import Card from "@/components/common/card/Card.vue";
import { Color } from "@/enums";
import {
  LocalizedActivityDTO,
  SelectionCriterionDto,
  SelectionCriterionDtoType,
  SelectionFiltersDto,
} from "@/lib/eduConfigurationServiceClient";
import SelectionCriterionExplainer from "@/components/selection/components/SelectionCriterionExplainer.vue";
import * as yup from "yup";
import { fields } from "@/utils/miscellaneous";
import { CardSize } from "@/components/common/card/Card.types";
import SelectionCriterionHeader from "@/components/selection/components/SelectionCriterionHeader.vue";
import { typeToComponentMapper } from "@/components/selection/SelectionCriterionForm.types";
const props = defineProps<{
  criterion: SelectionCriterionDto;
  context: CriterionFieldContext;
  filters: SelectionFiltersDto;
  readonly: boolean;
  activity?: LocalizedActivityDTO;
}>();

const emit = defineEmits<{
  submit: [values: SelectionCriterionDto];
  remove: [];
}>();

const field = fields<SelectionCriterionDto>();
const form = useForm<SelectionCriterionDto>({
  validationSchema: yup.object({
    [field.activityIds]: multiCriterionRule([
      SelectionCriterionDtoType.IsRegisteredForActivity,
      SelectionCriterionDtoType.IsNotRegisteredForActivity,
      SelectionCriterionDtoType.HasVisitedActivity,
      SelectionCriterionDtoType.HasNotVisitedActivity,
      SelectionCriterionDtoType.NotSubmittedSurveyForVisitedActivity,
    ]),
    [field.targetAudienceIds]: multiCriterionRule([
      SelectionCriterionDtoType.IsInTargetAudience,
      SelectionCriterionDtoType.IsNotTargetAudience,
    ]),
    [field.preEducationLevelIds]: multiCriterionRule([
      SelectionCriterionDtoType.HasPreEducation,
    ]),
    [field.languages]: multiCriterionRule([
      SelectionCriterionDtoType.HasLanguage,
    ]),
    [field.questionId]: singleCriterionRule([
      SelectionCriterionDtoType.HasGivenAnswer,
    ]),
    [field.answerOptionIds]: multiCriterionRule([
      SelectionCriterionDtoType.HasGivenAnswer,
    ]),
    [field.mailingIds]: multiCriterionRule([
      SelectionCriterionDtoType.NotReceivedMailing,
    ]),
    [field.studyProgramIds]: multiCriterionRule([
      SelectionCriterionDtoType.IsInterestedInStudyProgram,
      SelectionCriterionDtoType.HasApplied,
    ]),
    [field.thirdPartyProspectSourceIds]: multiCriterionRule([
      SelectionCriterionDtoType.ThirdPartyProspectSource,
    ]),
  }),
});

function singleCriterionRule(allowedTypes: SelectionCriterionDtoType[]) {
  return yup.string().when("type", {
    is: (type: SelectionCriterionDtoType) => allowedTypes.includes(type),
    then: (schema) => schema.required(),
  });
}

function multiCriterionRule(allowedTypes: SelectionCriterionDtoType[]) {
  return yup
    .array()
    .of(yup.string())
    .when("type", {
      is: (type: SelectionCriterionDtoType) => allowedTypes.includes(type),
      then: (schema) => schema.min(1).required(),
    });
}

const CriterionTypedComponent = computed(
  () => typeToComponentMapper.get(form.values.type) as FunctionalComponent,
);

form.setValues(props.criterion);

const submitForm = form.handleSubmit((values) => {
  emit("submit", values);
});

watch(
  form.values,
  async () => {
    submitForm();
  },
  { immediate: true },
);
</script>
