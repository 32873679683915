<template>
  <SlideOver
    v-model:visible="visibleInternal"
    :title="texts.generic.settings"
    :subTitle="texts.navigationItems.keepMeInformed.settings.description"
  >
    <KeepMeInformedConfigurationForm
      :selectedFormIds="selectedFormIds"
      @submit="submitCreate"
    >
      <ButtonSubmit :loading="actionLoading" class="md:col-start-2" />
      <ButtonCancel @click="() => (visibleInternal = false)" />
    </KeepMeInformedConfigurationForm>
  </SlideOver>
</template>

<script setup lang="ts">
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import logger from "@/plugins/logger";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";
import { computed, ref } from "vue";
import {
  KeepMeInformedConfigurationFormValues,
  convertToDTO,
} from "./KeepMeInformedConfigurationForm.types";
import KeepMeInformedConfigurationForm from "./KeepMeInformedConfigurationForm.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";

const props = defineProps<{
  visible: boolean;
  selectedFormIds?: string[];
}>();

const emit = defineEmits(["update:visible", "create:succeeded"]);

const visibleInternal = computed({
  get: () => props.visible,
  set: (value) => emit("update:visible", value),
});

// Create KeepMeInformedConfiguration
const actionLoading = ref<boolean>(false);
const submitCreate = (values: KeepMeInformedConfigurationFormValues) => {
  actionLoading.value = true;

  eduConfigurationServiceClient
    .createKeepMeInformedConfiguration(convertToDTO(values))
    .then(() => {
      Notify.success(texts.notifications.create.successCached, [
        texts.models.keepMeInformed.title,
      ]);
      visibleInternal.value = false;
      emit("create:succeeded");
    })
    .catch((e) => {
      Notify.failure(
        texts.navigationItems.keepMeInformed.settings.create.failure,
      );
      logger.error(e);
    })
    .finally(() => {
      actionLoading.value = false;
    });
};
</script>
