<template>
  <FormFieldMultiCriterion
    :id="fieldId"
    :initialValue="
      criterion.activityIds ? [...criterion.activityIds] : undefined
    "
    :options
    :disabled="readonly || criterion.isReadOnly"
  />
</template>

<script setup lang="ts">
import { TypedCriterionFieldProps } from "@/components/selection/SelectionCriterionForm.types";
import { SelectionCriterionDto } from "@/lib/eduConfigurationServiceClient";
import { fields } from "@/utils/miscellaneous";
import { DateTime } from "luxon";
import FormFieldMultiCriterion from "@/components/selection/type/components/FormFieldMultiCriterion.vue";
import { computed } from "vue";

const props = defineProps<TypedCriterionFieldProps>();

const fieldId = fields<SelectionCriterionDto>().activityIds;

const options = computed(() =>
  props.context.activities.map((activity) => ({
    id: activity.id,
    label: `${activity.name} (${activity.startDateTime.toLocaleString(
      DateTime.DATE_SHORT,
    )})`,
  })),
);
</script>
