<template>
  <SelectNative
    v-model="typeOption"
    :options="typeOptions"
    :placeholder="texts.actions.choice"
    @update:modelValue="addCriterion"
  />
</template>

<script setup lang="ts">
import SelectNative from "@/components/common/select/SelectNative.vue";
import { orderedCriterionTypes } from "@/components/selection/components/SelectionDefinitionAddCriterion.types";
import { isObsoleteCriterion } from "@/components/selection/SelectionCriterionForm.types";
import { SelectionCriterionDtoType } from "@/lib/eduConfigurationServiceClient";
import texts from "@/utils/texts";
import { computed, ref } from "vue";

const props = defineProps<{
  exclude?: SelectionCriterionDtoType[];
}>();

const emit = defineEmits<{
  add: [type: SelectionCriterionDtoType];
}>();

const typeOption = ref<string | undefined>();
const typeOptions = computed(() =>
  orderedCriterionTypes
    .filter((type) => type !== SelectionCriterionDtoType.Unknown)
    .filter((type) => !isObsoleteCriterion(type))
    .filter((type) => !props.exclude || props.exclude?.includes(type) === false)
    .map((type) => ({
      value: type,
      label: texts.enums.selectionType[type],
    })),
);

function addCriterion(type: string) {
  typeOption.value = undefined;
  emit("add", type as SelectionCriterionDtoType);
}
</script>
