<template>
  <div class="flex flex-col gap-2">
    <div
      v-if="selectedOptions.length"
      class="flex flex-wrap items-center gap-1.5"
    >
      <template v-if="showStudyProgramCount">
        <Tag
          >{{ selectedOptions.length }}
          {{ texts.models.studyProgram.plural }}</Tag
        >
      </template>
      <template v-else>
        <Tag v-for="option in selectedOptions" :key="option.id">
          {{ option.label }}
        </Tag>
      </template>
      <button
        v-if="!criterion.isReadOnly && !readonly"
        type="button"
        @click="showAddStudyPrograms = !showAddStudyPrograms"
      >
        <Icon
          icon="edit"
          :size="IconSize.sm"
          :color="Color.Lavender"
          clickable
        />
      </button>
    </div>
    <TransitionMenu>
      <div
        v-if="showAddStudyPrograms"
        :class="[
          'flex flex-col gap-1 rounded-md p-4 shadow-lg ring-1 focus:outline-none focus:ring-2 focus:ring-lavender-500',
          errorMessage ? 'ring-alert-500/50' : 'ring-black/5',
        ]"
      >
        <button
          class="self-end"
          type="button"
          @click="showAddStudyPrograms = false"
        >
          <Icon
            icon="close"
            :size="IconSize.sm"
            :color="Color.DeepTeal"
            clickable
          />
        </button>
        <StudyProgramPicker
          v-model:selectedIds="value"
          :studyPrograms="settings.allStudyPrograms"
        />
        <span v-if="errorMessage" class="text-xs text-alert-700">
          {{ errorMessage }}
        </span>
      </div>
    </TransitionMenu>
  </div>
</template>

<script setup lang="ts">
import StudyProgramPicker from "@/components/study-programs/study-program-picker/StudyProgramPicker.vue";
import { TypedCriterionFieldProps } from "@/components/selection/SelectionCriterionForm.types";
import * as yup from "yup";
import { useField } from "vee-validate";
import { fields } from "@/utils/miscellaneous";
import { SelectionCriterionDto } from "@/lib/eduConfigurationServiceClient";
import settings from "@/store/context/settings.context";
import { computed, ref, watch } from "vue";
import Tag from "@/components/common/tag/Tag.vue";
import Icon from "@/components/common/icon/Icon.vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import { Color } from "@/enums";
import texts from "@/utils/texts";
import TransitionMenu from "@/components/common/transition/TransitionMenu.vue";

const props = defineProps<TypedCriterionFieldProps>();

const id = fields<SelectionCriterionDto>().studyProgramIds;

const { value, errorMessage } = useField<
  typeof props.criterion.studyProgramIds
>(id, yup.array().required().min(1), {
  initialValue: [...(props.criterion.studyProgramIds ?? [])], // Shallow-copy to decouple field-array from the original
});

const options = computed(() => {
  return settings.allStudyPrograms.map((studyProgram) => ({
    id: studyProgram.id,
    label: studyProgram.displayName,
  }));
});

const selectedOptions = computed(() =>
  options.value
    .filter((option) => value.value?.includes(option.id))
    .filter((option) => option !== undefined),
);

const showStudyProgramCount = computed(() => {
  return selectedOptions.value.length > 10;
});

const showAddStudyPrograms = ref(false);

watch(
  value,
  () => {
    if (!value.value || value.value.length === 0) {
      showAddStudyPrograms.value = true;
    }
  },
  { immediate: true },
);
</script>
