<template>
  <template
    v-if="
      showStudyProgramExplainer &&
      filters.studyProgramFilter.studyProgramIds &&
      filters.studyProgramFilter.studyProgramIds.length > 0
    "
  >
    <p class="flex flex-wrap items-center gap-1.5">
      <span class="font-bold">{{ texts.generic.and.toUpperCase() }}</span
      >{{
        ` ${texts.navigationItems.mailing.steps.selectionDefinition.inStudyPrograms}`
      }}
      <template v-if="studyProgramLabels.length <= 10">
        <Tag
          v-for="item in studyProgramLabels"
          :key="item"
          :color="Color.Lavender"
        >
          {{ item }}
        </Tag>
      </template>
      <template v-else>
        <Tag :color="Color.Lavender">
          {{ studyProgramLabels.length }}
          {{ texts.models.studyProgram.plural }}</Tag
        >
      </template>
    </p>
  </template>
  <template
    v-if="
      showStudyProgramExplainer && filters.studyProgramFilter.activityIds.length
    "
  >
    <p
      v-if="filters.studyProgramFilter.activityIds?.length && activity"
      class="flex flex-wrap items-center gap-1.5"
    >
      <span class="font-bold">{{ texts.generic.and.toUpperCase() }}</span>
      {{
        ` ${texts.navigationItems.mailing.steps.selectionDefinition.inStudyProgramsFromActivity}`
      }}
      <Tag :color="Color.Lavender"
        >{{ activity.name }} ({{
          activity.startDateTime.toLocaleString(DateTime.DATE_SHORT)
        }})</Tag
      >
    </p>
  </template>
  <template v-if="showDateRangeExplainer && filters.dateRange">
    <p class="flex flex-wrap items-center gap-1.5">
      <span class="font-bold">{{ texts.generic.and.toUpperCase() }}</span
      >{{
        ` ${texts.navigationItems.mailing.steps.selectionDefinition.inPeriod}`
      }}
      <Tag :color="Color.Lavender">
        {{
          Interval.fromDateTimes(
            filters.dateRange.from,
            filters.dateRange.to,
          ).toLocaleString(DateTime.DATE_SHORT)
        }}
      </Tag>
    </p>
  </template>
  <template v-if="isObsoleteCriterion(criterion.type)">
    <Alert :color="Color.Warning">
      {{ texts.selections.obsoleteMessage }}
      {{ obsoleteMessageForCriterionType }}
    </Alert>
  </template>
</template>

<script setup lang="ts">
import texts from "@/utils/texts";
import { DateTime, Interval } from "luxon";
import { computed } from "vue";
import settings from "@/store/context/settings.context";
import {
  doesCriterionSupportFilter,
  SelectionFilterType,
} from "@/components/selection/components/SelectionCriterionExplainer.types";
import Tag from "@/components/common/tag/Tag.vue";
import { Color } from "@/enums";
import {
  LocalizedActivityDTO,
  SelectionCriterionDto,
  SelectionCriterionDtoType,
  SelectionFiltersDto,
} from "@/lib/eduConfigurationServiceClient";
import Alert from "@/components/common/alert/Alert.vue";
import { isObsoleteCriterion } from "@/components/selection/SelectionCriterionForm.types";

const props = defineProps<{
  criterion: SelectionCriterionDto;
  filters: SelectionFiltersDto;
  activity?: LocalizedActivityDTO;
}>();

const showStudyProgramExplainer = computed(() =>
  doesCriterionSupportFilter(
    props.criterion.type,
    SelectionFilterType.StudyProgram,
  ),
);

const showDateRangeExplainer = computed(() =>
  doesCriterionSupportFilter(
    props.criterion.type,
    SelectionFilterType.DateRange,
  ),
);

const studyProgramLabels = computed(() =>
  props.filters.studyProgramFilter.studyProgramIds.map(
    (id) => settings.allStudyPrograms.find((sp) => sp.id === id)?.displayName,
  ),
);

const obsoleteMessageForCriterionType = computed(() => {
  switch (props.criterion.type) {
    case SelectionCriterionDtoType.IsInterestedInStudyProgram:
      return texts.selections.selectionTypeObsoleteMessage
        .IsInterestedInStudyProgram;
    case SelectionCriterionDtoType.ShowedInterestInCurrentYear:
      return texts.selections.selectionTypeObsoleteMessage
        .ShowedInterestInCurrentYear;
    default:
      return "";
  }
});
</script>
