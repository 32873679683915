<template>
  <List>
    <template v-slot:header>
      <ListItemColumn>{{ texts.models.affiliate.name }}</ListItemColumn>
      <ListItemColumn>{{
        texts.models.preEducationSchool.name
      }}</ListItemColumn>
    </template>
    <ListItem
      v-for="affiliate in affiliates"
      :key="affiliate.id"
      clickable
      @click="emit('click', affiliate)"
    >
      <ListItemColumn>{{ affiliate.name }}</ListItemColumn>
      <ListItemColumn>{{
        schools?.find((s) => s.id === affiliate.preEducationSchoolId)
          ?.displayName
      }}</ListItemColumn>
    </ListItem>
  </List>
</template>

<script setup lang="ts">
import texts from "@/utils/texts";
import List from "@/components/common/list/List.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import ListItem from "@/components/common/list/ListItem.vue";
import {
  IAffiliateDto,
  IPreEducationSchool,
} from "@/lib/eduConfigurationServiceClient";

defineProps<{
  affiliates: IAffiliateDto[];
  schools: IPreEducationSchool[];
}>();

const emit = defineEmits<{
  click: [IAffiliateDto];
}>();
</script>
