<template>
  <div class="flex items-start justify-between gap-8">
    <div
      :class="[
        'border border-transparent text-base text-deepteal-600 xl:text-lg xl:font-normal',
      ]"
    >
      {{ texts.enums.selectionType[criterion.type] }}
    </div>
    <div class="flex gap-2">
      <template v-if="!readonly">
        <button type="button">
          <Icon
            class="cursor-pointer self-end"
            icon="delete"
            :color="Color.Gray"
            :size="IconSize.sm"
            @click="emit('remove')"
          />
        </button>
      </template>
      <template v-else-if="criterion.isReadOnly">
        <div
          class="'flex transition-colors' items-center gap-2 rounded bg-deepteal-200 px-2 py-1 text-xs font-medium leading-none text-deepteal-700"
        >
          {{ componentTexts.requiredCriterion }}
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IconSize } from "@/components/common/icon/Icon.types";
import Icon from "@/components/common/icon/Icon.vue";
import { Color } from "@/enums";
import { ISelectionCriterionDto } from "@/lib/eduConfigurationServiceClient";
import texts from "@/utils/texts";

defineProps<{
  criterion: ISelectionCriterionDto;
  readonly?: boolean;
}>();

const emit = defineEmits<{
  remove: [];
}>();

const componentTexts = texts.navigationItems.mailing.steps.selectionDefinition;
</script>
