<template>
  <PopoverWrapper :panelOrigin>
    <template v-slot:button>
      <PopoverButton
        :as="Button"
        :color="definition.filters.dateRange ? Color.Lavender : Color.Gray"
        :size="ButtonSize.sm"
        :trailingIcon="'keyboard_arrow_down'"
        :disabled
        :data-testid="testIds.dashboard.selectionPeriodFilter.button"
        round
      >
        <span v-if="definition.filters.dateRange">{{
          Interval.fromDateTimes(
            definition.filters.dateRange.from,
            definition.filters.dateRange.to,
          ).toLocaleString(DateTime.DATE_SHORT)
        }}</span>
        <span v-else>&nbsp;–&nbsp; </span>
      </PopoverButton>
    </template>
    <template v-slot:panel="{ close }">
      <SelectionPeriodFilterForm
        :recruitmentYear="recruitmentYear"
        :formValues="{
          start: definition.filters.dateRange?.from,
          end: definition.filters.dateRange?.to,
        }"
        :apply
        @submit="
          (values) => {
            onSubmit(values);
            if (!apply) close();
          }
        "
        @cancel="close()"
      />
    </template>
  </PopoverWrapper>
</template>

<script setup lang="ts">
import { ButtonSize } from "@/components/common/button/Button.types";
import Button from "@/components/common/button/Button.vue";
import { Color } from "@/enums.js";
import {
  DateRangeDto,
  ISelectionDefinitionDto,
  RecruitmentYearDTO,
  SelectionDefinitionDto,
} from "@/lib/eduConfigurationServiceClient";
import { DateTime, Interval } from "luxon";
import PopoverWrapper from "@/components/common/popover/PopoverWrapper.vue";
import { PanelOrigin } from "@/components/common/popover/PopoverWrapper.types";
import { PopoverButton } from "@headlessui/vue";
import { testIds } from "@/utils/testing";
import SelectionPeriodFilterForm, {
  SelectionPeriodFormValues,
} from "@/components/selection/filter/components/SelectionPeriodFilterForm.vue";

withDefaults(
  defineProps<{
    recruitmentYear: RecruitmentYearDTO;
    panelOrigin?: PanelOrigin;
    disabled?: boolean;
    apply?: boolean;
  }>(),
  {
    panelOrigin: PanelOrigin.TopLeft,
    disabled: false,
    apply: false,
  },
);

const definition = defineModel<ISelectionDefinitionDto>({
  required: true,
});

const onSubmit = (values: SelectionPeriodFormValues) => {
  definition.value = new SelectionDefinitionDto({
    ...definition.value,
    filters: {
      ...definition.value.filters,
      dateRange: new DateRangeDto({
        from: values.start,
        to: values.end,
      }),
    },
  });
};
</script>
