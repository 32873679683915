<template>
  <div class="flex flex-col gap-3 lg:flex-row lg:flex-wrap">
    <div class="flex flex-col gap-1.5 sm:flex-2">
      <span>{{ texts.models.question.title }}</span>
      <SingleCriterion
        v-model="questionId"
        :options="questions"
        :valid="!questionIdError"
        :disabled="readonly || criterion.isReadOnly"
        class="flex-1"
      />
    </div>
    <div
      v-if="questionId"
      class="flex flex-col gap-1.5 lg:flex-3 lg:border-l lg:border-gray-200 lg:pl-2"
    >
      <span>{{ texts.models.answer.title }}</span>
      <MultiCriterion
        v-model="answerOptionIds"
        :options="answerOptions(questionId)"
        :initialValue="
          criterion.answerOptionIds ? [...criterion.answerOptionIds] : undefined
        "
        :valid="!answerIdsError"
        :disabled="readonly || criterion.isReadOnly"
        class="flex-1"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { TypedCriterionFieldProps } from "@/components/selection/SelectionCriterionForm.types";
import { fields } from "@/utils/miscellaneous";
import { SelectionCriterionDto } from "@/lib/eduConfigurationServiceClient";
import SingleCriterion from "@/components/selection/type/components/SingleCriterion.vue";
import MultiCriterion from "@/components/selection/type/components/MultiCriterion.vue";
import { watch } from "vue";
import { useField } from "vee-validate";
import * as yup from "yup";
import texts from "@/utils/texts";

const props = defineProps<TypedCriterionFieldProps>();

const questionFieldId = fields<SelectionCriterionDto>().questionId;

const { value: questionId, errorMessage: questionIdError } = useField<
  typeof props.criterion.questionId
>(questionFieldId, yup.string().required(), {
  initialValue: props.criterion.questionId,
});

const answerFieldId = fields<SelectionCriterionDto>().answerOptionIds;

const { value: answerOptionIds, errorMessage: answerIdsError } = useField<
  typeof props.criterion.answerOptionIds
>(answerFieldId, yup.array().required().min(1), {
  initialValue: props.criterion.answerOptionIds
    ? [...props.criterion.answerOptionIds]
    : undefined, // Shallow-copy to decouple field-array from the original
});

const questions = computed(() =>
  props.context.questionsWithAnswers.map((question) => ({
    id: question.id,
    label: question.label,
  })),
);

const answerOptions = (questionId: string) =>
  props.context.questionsWithAnswers
    .filter((question) => question.id === questionId)[0]
    .answers.map((answer) => ({
      id: answer.id,
      label: answer.label,
    }));

watch(questionId, () => {
  answerOptionIds.value = undefined;
});
</script>
