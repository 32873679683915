<template>
  <Page :error="error" :loading="loading" class="h-full">
    <Wizard
      v-if="data"
      :steps="steps"
      :culture="settings.culture"
      :data="data"
      :contextData="contextData"
      :nextDisabled="isLoadingAction"
      :nextLoading="isFinishing"
      :previousDisabled="isLoadingAction"
      :closeDisabled="isLoadingAction"
      :saveDisabled="isLoadingAction"
      :saveLoading="isSaving"
      @save="onSave"
      @close="onClose"
      @finish="onFinish"
    >
      <template v-slot:header>
        <Section>
          <PageHeading
            v-if="mailing"
            :title="mailing.name"
            :badgeLabel="texts.enums.mailingStatus[mailing.status]"
            :badgeType="statusBadgeType"
          />
          <PageHeading v-else :title="texts.navigationItems.mailing.title" />
        </Section>
      </template>
    </Wizard>
  </Page>
</template>

<script setup lang="ts">
import {
  Culture,
  FormType as FormTypeEnum,
  MailingType as MailingTypeEnum,
} from "@/enums";
import { computed, ref } from "vue";

import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import Page from "@/components/common/page/Page.vue";
import { WizardStep } from "@/components/common/wizard/Wizard.types";
import Wizard from "@/components/common/wizard/Wizard.vue";
import {
  CompleteMailingData,
  ConceptMailingData,
  convertToConceptMailingUpdateDTO,
  convertToPlannedMailingUpdateDTO,
  saveOrUpdateSelectionDefinition,
  MailingContextData,
} from "@/views/mailings/mailing/MailingWizard.types";

import { RouteNames } from "@/router/routeNames";

// Steps
import { BadgeType } from "@/components/common/badge/BadgeProps";
import { Form } from "@/models/form";
import { MailingExtended } from "@/models/mailing";
import logger from "@/plugins/logger";
import {
  getFromEmailAdresses,
  getReplyToEmailAdresses,
} from "@/services/email-address";
import { formsServiceClient } from "@/services/formsService.client.service";
import {
  createConceptMailing,
  createPlannedMailing,
  getMailing,
  updateConceptMailing,
  updatePlannedMailing,
} from "@/services/mailings.service";
import Notify from "@/utils/notify";
import { useRoute, useRouter } from "vue-router";
import {
  convertToConceptMailingCreateDTO,
  convertToMailingConceptData,
  convertToPlannedMailingCreateDTO,
} from "./MailingWizard.types";
import { convertToBadgeType } from "./Overview.types";
import BasicInformation from "./steps/BasicInformation.vue";
import Overview from "./steps/Overview.vue";
import SelectionDefinitionStep from "./steps/SelectionDefinition.vue";
import Template from "./steps/Template.vue";
import Timing from "./steps/Timing.vue";
import Section from "@/components/common/section/Section.vue";
import { loadCriterionFieldContext } from "@/components/selection/SelectionCriterionForm.context";
import settings from "@/store/context/settings.context";
import selection from "@/store/context/selection.context";
import texts from "@/utils/texts";
import { LocalizedActivityDTO } from "@/lib/eduConfigurationServiceClient";

const props = defineProps<{ mailingType: MailingTypeEnum }>();

// Router setup
const router = useRouter();

// Route params
const route = useRoute();
const routeParamId = (route.params["id"] as string) || null;
const routeQueryUseSelection =
  (route.query["useDashboardSelection"] as string) || null;
const routeQueryActivityId = (route.query["activityId"] as string) || null;
const routeQueryReturnUrl = (route.query["returnUrl"] as string) || null;

// Fetch mail data
const loading = ref<boolean>(false);
const error = ref<boolean>(false);

const selectionDefinitionId = ref<string>();
const mailingId = ref<string>();
const mailing = ref<MailingExtended>();

const statusBadgeType = computed<BadgeType>(() =>
  mailing.value ? convertToBadgeType(mailing.value.status) : BadgeType.default,
);

// Wizard main data
const data = ref<ConceptMailingData>();

// Wizard context data
const contextData = ref<MailingContextData>();

if (routeParamId) {
  // If we are editing an existing mailing
  loading.value = true;
  Promise.all([
    getMailing(routeParamId),
    getFromEmailAdresses(true),
    getReplyToEmailAdresses(),
    formsServiceClient.getForms(FormTypeEnum.ActivityVisitedSurvey),
    loadCriterionFieldContext(),
  ])
    .then(
      ([
        mailingExtended,
        fromEmailAddresses,
        replyToEmailAddresses,
        forms,
        context,
      ]) => {
        selectionDefinitionId.value = mailingExtended.selectionDefinitionId;
        mailingId.value = mailingExtended.id;
        mailing.value = mailingExtended;

        const mailingType = settings.mailingTypes.filter(
          (type) => type.mailingType === mailingExtended.type,
        )[0];

        if (!mailingType) {
          throw new Error(
            `Could not find mailing type ${mailingExtended.type}`,
          );
        }

        contextData.value = {
          id: routeParamId,
          fromEmailAddresses,
          replyToEmailAddresses,
          mailingType,
          surveyForms: forms.map((dto) => new Form(dto)),
          activities: context.activities,
          languages: context.languages,
          targetAudiences: context.targetAudiences,
          questionsWithAnswers: context.questionsWithAnswers,
          mailings: context.mailings,
          preEducationLevels: context.preEducationLevels,
          thirdPartyProspectSources: context.thirdPartyProspectSources,
          disableActivitySelection: !!routeQueryActivityId,
        };
        return convertToMailingConceptData(mailingExtended, context.activities);
      },
    )
    .then((conceptData) => {
      data.value = conceptData;
    })
    .catch((e) => {
      error.value = true;
      logger.error(e);
    })
    .finally(() => {
      loading.value = false;
    });
} else {
  // If we are creating a new mailing
  loading.value = true;
  Promise.all([
    getFromEmailAdresses(true),
    getReplyToEmailAdresses(),
    formsServiceClient.getForms(FormTypeEnum.ActivityVisitedSurvey),
    loadCriterionFieldContext(),
  ])
    .then(([fromEmailAddresses, replyToEmailAddresses, forms, context]) => {
      if (!props.mailingType) {
        new Error("MailingType prop is required but not defined.");
      }

      const mailingType = settings.mailingTypes.filter(
        (type) => type.mailingType === props.mailingType,
      )[0];

      if (!mailingType) {
        throw new Error(`Could not find mailing type ${props.mailingType}`);
      }

      let selectionDefinition = undefined;
      if (routeQueryUseSelection) {
        if (!selection.definition) {
          throw new Error("Selection definition is required but not defined.");
        }
        selectionDefinition = selection.definition;
      }

      let activity: LocalizedActivityDTO | undefined;
      if (routeQueryActivityId) {
        activity = context.activities.find(
          (a) => a.id === routeQueryActivityId,
        );
      }

      contextData.value = {
        fromEmailAddresses,
        replyToEmailAddresses,
        mailingType,
        surveyForms: forms.map((dto) => new Form(dto)),
        activities: context.activities,
        targetAudiences: context.targetAudiences,
        languages: context.languages,
        questionsWithAnswers: context.questionsWithAnswers,
        mailings: context.mailings,
        preEducationLevels: context.preEducationLevels,
        thirdPartyProspectSources: context.thirdPartyProspectSources,
        disableActivitySelection: !!routeQueryActivityId,
      };
      data.value = {
        type: mailingType.mailingType,
        locale: settings.mainLanguage.locale.value as Culture,
        activity,
        selectionDefinition,
      };
    })
    .catch((e) => {
      error.value = true;
      logger.error(e);
    })
    .finally(() => {
      loading.value = false;
    });
}

// Wizard steps
const steps: WizardStep[] = [
  {
    title: texts.navigationItems.mailing.steps.basicInformation.title,
    content: BasicInformation,
  },
  {
    title: texts.navigationItems.mailing.steps.template.title,
    content: Template,
  },

  {
    title: texts.navigationItems.mailing.steps.selectionDefinition.title,
    content: SelectionDefinitionStep,
  },
  {
    title: texts.navigationItems.mailing.steps.timing.title,
    content: Timing,
  },
  {
    title: texts.navigationItems.mailing.steps.overview.title,
    content: Overview,
  },
];

const isSaving = ref(false);
const onSave = async (saveResult: ConceptMailingData) => {
  isSaving.value = true;

  try {
    if (saveResult.selectionDefinition) {
      selectionDefinitionId.value = await saveOrUpdateSelectionDefinition(
        saveResult.selectionDefinition,
        selectionDefinitionId.value,
      );
    }
  } catch (error) {
    Notify.failure(
      texts.navigationItems.mailing.saveSelectionDefinition.failure,
    );
    isSaving.value = false;
    throw error;
  }

  if (mailingId.value) {
    updateConceptMailing(
      convertToConceptMailingUpdateDTO(
        mailingId.value,
        saveResult,
        selectionDefinitionId.value,
      ),
    )
      .then(() => {
        if (contextData.value) {
          contextData.value.id = mailingId.value;
        }
        Notify.success(texts.navigationItems.mailing.update.success);
      })
      .catch((error) => {
        Notify.failure(texts.navigationItems.mailing.update.failure);
        logger.error(error);
      })
      .finally(() => {
        isSaving.value = false;
      });
  } else {
    createConceptMailing(
      convertToConceptMailingCreateDTO(saveResult, selectionDefinitionId.value),
    )
      .then((data) => {
        mailingId.value = data.id;
        if (contextData.value) {
          contextData.value.id = mailingId.value;
        }
        Notify.success(texts.navigationItems.mailing.create.success);
      })
      .catch((error) => {
        Notify.failure(texts.navigationItems.mailing.create.failure);
        logger.error(error);
      })
      .finally(() => {
        isSaving.value = false;
      });
  }
};

const onClose = () => {
  if (routeQueryReturnUrl) {
    router.push(routeQueryReturnUrl);
  } else {
    router.push({
      name: RouteNames.MAILINGS,
    });
  }
};

const isFinishing = ref(false);
const isNavigatingToOverview = ref(false);
const onFinish = async (finishResult: CompleteMailingData) => {
  isFinishing.value = true;

  try {
    selectionDefinitionId.value = await saveOrUpdateSelectionDefinition(
      finishResult.selectionDefinition,
      selectionDefinitionId.value,
    );
  } catch (error) {
    if (mailingId.value) {
      Notify.failure(texts.navigationItems.mailing.update.failure);
    } else {
      Notify.failure(texts.navigationItems.mailing.create.failure);
    }

    logger.error(error as Error);

    isFinishing.value = false;
    return;
  }

  const selDefId = selectionDefinitionId.value;
  if (!selDefId)
    throw new Error(
      "Could not finish mailing because selectionDefinitionId has no value",
    );

  if (mailingId.value) {
    updatePlannedMailing(
      convertToPlannedMailingUpdateDTO(mailingId.value, finishResult, selDefId),
    )
      .then(() => {
        Notify.success(texts.navigationItems.mailing.update.success);
        isNavigatingToOverview.value = true;
        onClose();
      })
      .catch((error) => {
        Notify.failure(texts.navigationItems.mailing.update.failure);
        logger.error(error);
      })
      .finally(() => {
        isFinishing.value = false;
      });
  } else {
    createPlannedMailing(
      convertToPlannedMailingCreateDTO(finishResult, selDefId),
    )
      .then((data) => {
        mailingId.value = data.id;
        Notify.success(texts.navigationItems.mailing.create.success);
        isNavigatingToOverview.value = true;
        onClose();
      })
      .catch((error) => {
        Notify.failure(texts.navigationItems.mailing.create.failure);
        logger.error(error);
      })
      .finally(() => {
        isFinishing.value = false;
      });
  }
};

const isLoadingAction = computed(
  () => isFinishing.value || isSaving.value || isNavigatingToOverview.value,
);
</script>
