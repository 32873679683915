import { IconNames } from "@/components/common/icon/Icon.types";

export interface ModalProps {
  visible: boolean;
  type?: ModalType;
  icon?: IconNames;
  title?: string;
  description?: string;
  centered?: boolean;
}

export enum ModalType {
  warning = "warning",
  danger = "danger",
  default = "default",
  success = "success",
}

export enum ModalSize {
  Normal = "normal",
  Large = "large",
}
