<template>
  <FormFieldMultiCriterion
    :id
    :options
    :initialValue="
      criterion.thirdPartyProspectSourceIds
        ? [...criterion.thirdPartyProspectSourceIds]
        : undefined
    "
    :disabled="readonly || criterion.isReadOnly"
  />
</template>

<script setup lang="ts">
import { TypedCriterionFieldProps } from "@/components/selection/SelectionCriterionForm.types";
import { fields } from "@/utils/miscellaneous";
import { SelectionCriterionDto } from "@/lib/eduConfigurationServiceClient";
import FormFieldMultiCriterion from "@/components/selection/type/components/FormFieldMultiCriterion.vue";
import { computed } from "vue";

const props = defineProps<TypedCriterionFieldProps>();

const id = fields<SelectionCriterionDto>().thirdPartyProspectSourceIds;

const options = computed(() =>
  props.context.thirdPartyProspectSources.map((source) => ({
    id: source.id,
    label: source.name,
  })),
);
</script>
