<template>
  <button
    type="button"
    class="flex items-center gap-1 text-nowrap font-medium"
    :disabled
  >
    <div
      :class="[
        {
          'text-xs': size === ButtonSize.xs,
          'text-sm': size === ButtonSize.sm,
          'text-base': size === ButtonSize.md,
          'text-lg': size === ButtonSize.lg,
          'text-xl': size === ButtonSize.xl,
        },
        getTextColor(color),
        getTextDecorationColor(color),
        'flex items-center gap-2',
        disabled ? 'opacity-40' : 'hover:opacity-70',
      ]"
      role="button"
      tabindex="0"
    >
      <Icon
        v-if="leadingIcon"
        :icon="leadingIcon"
        :size="iconSize"
        :color="color"
      ></Icon>

      <span class="flex items-center gap-2 underline">
        {{ label }}
        <slot></slot
      ></span>

      <Icon
        v-if="trailingIcon"
        :icon="trailingIcon"
        :size="iconSize"
        :color="color"
      />
    </div>
  </button>
</template>

<script setup lang="ts">
import { ButtonSize } from "@/components/common/button/Button.types";
import { IconNames, IconSize } from "@/components/common/icon/Icon.types";
import Icon from "@/components/common/icon/Icon.vue";
import { computed } from "vue";
import { getTextColor, getTextDecorationColor } from "@/utils/color";
import { Color } from "@/enums";

const props = defineProps<{
  leadingIcon?: IconNames;
  trailingIcon?: IconNames;
  disabled?: boolean;
  label?: string;
  size?: ButtonSize;
  color?: Color;
}>();

const iconSize = computed(() => {
  switch (props.size) {
    case ButtonSize.xs:
      return IconSize.xs;
    case ButtonSize.sm:
      return IconSize.sm;
    case ButtonSize.md:
      return IconSize.md;
    case ButtonSize.lg:
      return IconSize.lg;
    case ButtonSize.xl:
      return IconSize.xl;
    default:
      return IconSize.md;
  }
});
</script>
