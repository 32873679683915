<template>
  <FormFieldMultiCriterion
    :id
    :options
    :initialValue="criterion.mailingIds ? [...criterion.mailingIds] : undefined"
    :disabled="readonly || criterion.isReadOnly"
  />
</template>

<script setup lang="ts">
import { TypedCriterionFieldProps } from "@/components/selection/SelectionCriterionForm.types";
import {
  MailingSelectionDefinitionDto,
  SelectionCriterionDto,
} from "@/lib/eduConfigurationServiceClient";
import { fields } from "@/utils/miscellaneous";
import { DateTime } from "luxon";
import { computed } from "vue";
import FormFieldMultiCriterion from "@/components/selection/type/components/FormFieldMultiCriterion.vue";

const props = defineProps<TypedCriterionFieldProps>();

const id = fields<SelectionCriterionDto>().mailingIds;

const options = computed(() =>
  props.context.mailings.map((mailing) => ({
    id: mailing.id,
    label: formatLabel(mailing),
  })),
);

const formatLabel = (mailing?: MailingSelectionDefinitionDto) => {
  if (!mailing) return "";
  const mailingDateTime = mailing.sentDateTime ?? mailing.plannedDateTime;
  return `${mailing.name} (${
    mailingDateTime
      ? mailingDateTime.toLocaleString(DateTime.DATETIME_SHORT)
      : ""
  })`;
};
</script>
