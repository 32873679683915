<template>
  <List>
    <template v-slot:header>
      <ListItemColumn :flex="1">{{
        texts.models.affiliate.name
      }}</ListItemColumn>
      <ListItemColumn :flex="1" hide>
        {{ texts.models.affiliate.emailAddress }}
      </ListItemColumn>
      <ListItemColumn :flex="1">{{
        texts.models.preEducationSchool.name
      }}</ListItemColumn>
      <ListItemColumn :flex="1" hide>
        <RegistrantAndVisitorLegend
          :lavender="texts.models.activity.registrants"
          :lime="texts.models.activity.visitors"
        />
      </ListItemColumn>
      <ListItemColumn :flex="0"><div class="h-6 w-6"></div></ListItemColumn>
    </template>
    <ListItem
      v-for="link in affiliateLinks"
      :key="link.id"
      clickable
      @click="emit('click', link)"
    >
      <ListItemColumn :flex="1">{{ link.affiliate.name }}</ListItemColumn>
      <ListItemColumn :flex="1" hide>{{
        link.affiliate.emailAddress
      }}</ListItemColumn>
      <ListItemColumn :flex="1">{{
        getAffiliateSchoolLabel(link.affiliate)
      }}</ListItemColumn>
      <ListItemColumn :flex="1" class="justify-items-start" hide>
        <p class="font-bold">
          <span class="text-lavender-500"> {{ link.registrantCount }}</span>
          <span class="text-gray-500"> / </span>
          <span class="text-lime-500">{{ link.visitorCount }}</span>
        </p>
      </ListItemColumn>
      <ListItemColumn :flex="0">
        <ListItemActions
          class="text-wrap"
          :loading="isDownloading == link.affiliateId"
          @click.stop
        >
          <DropdownItem @click.stop="copyLink(link)">
            <Icon icon="link" />
            <span>{{ texts.actions.copyLink }}</span>
          </DropdownItem>
          <Divider class="mx-2" />
          <DropdownItem
            :href="`mailto:${link.affiliate.emailAddress}?subject=${
              texts.navigationItems.organize.activity.affiliateLinks.mail
                .subject
            }&body=${interpolate(
              texts.navigationItems.organize.activity.affiliateLinks.mail.body,
              link.affiliate.name,
              link.uri,
            )}`"
          >
            <Icon icon="send" />
            <span>{{
              texts.navigationItems.organize.activity.affiliateLinks.mail.button
            }}</span>
          </DropdownItem>
          <DropdownItem @click="sendReportMailLink(link.affiliateId)">
            <Icon icon="send" />
            <span>{{
              texts.navigationItems.organize.activity.affiliateLinks.reportMail
                .button
            }}</span>
          </DropdownItem>
          <Divider class="mx-2" />
          <DropdownItem @click="downloadReport(link.affiliateId)">
            <Icon icon="download" />
            <span>{{
              texts.navigationItems.organize.activity.affiliateLinks
                .downloadButton
            }}</span>
          </DropdownItem>
        </ListItemActions>
      </ListItemColumn>
    </ListItem>
  </List>
</template>

<script setup lang="ts">
import {
  IAffiliateDto,
  IAffiliateLinkOverviewItemDto,
  IPreEducationSchool,
} from "@/lib/eduConfigurationServiceClient";
import List from "@/components/common/list/List.vue";
import texts from "@/utils/texts";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import ListItem from "@/components/common/list/ListItem.vue";
import RegistrantAndVisitorLegend from "@/components/common/legend/RegistrantAndVisitorLegend.vue";
import Icon from "@/components/common/icon/Icon.vue";
import { computed, ref } from "vue";
import ListItemActions from "@/components/common/list/ListItemActions.vue";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import Divider from "@/components/common/divider/Divider.vue";
import { interpolate } from "@/dictionary";
import {
  downloadAffiliateLinkReport,
  sendAffiliateLinkRegistrationsReportMail,
} from "@/views/organize/activities/activity/affiliate-links/AffiliateLink.types";

const props = defineProps<{
  affiliateLinks: IAffiliateLinkOverviewItemDto[];
  preEducationSchools: IPreEducationSchool[];
  activityId: string;
}>();

const emit = defineEmits<{
  click: [affiliate: IAffiliateLinkOverviewItemDto];
}>();

const hasCopied = ref<string>();
const copyLink = async (link: IAffiliateLinkOverviewItemDto) => {
  if (hasCopied.value) return;

  await navigator.clipboard.writeText(link.uri);
  hasCopied.value = link.id;
  setTimeout(() => (hasCopied.value = undefined), 1000);
};

const isDownloading = ref<string>("");
const downloadReport = async (affiliateId: string) => {
  if (isDownloading.value) return;
  isDownloading.value = affiliateId;
  await downloadAffiliateLinkReport(props.activityId, affiliateId).finally(
    () => {
      isDownloading.value = "";
    },
  );
};

const isSendingReportMail = ref<string>("");
const sendReportMailLink = async (affiliateId: string) => {
  if (isSendingReportMail.value) return;
  isSendingReportMail.value = affiliateId;
  await sendAffiliateLinkRegistrationsReportMail(
    props.activityId,
    affiliateId,
  ).finally(() => {
    isSendingReportMail.value = "";
  });
};

const getAffiliateSchoolLabel = (affiliate: IAffiliateDto) =>
  computed(() => {
    const school = props.preEducationSchools.find(
      (s) => s.id === affiliate.preEducationSchoolId,
    );
    if (!school) return "";
    return school.displayName;
  });
</script>
