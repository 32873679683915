<template>
  <div class="flex items-center gap-4">
    <Icon
      icon="stacked_email"
      :size="IconSize.lg"
      :class="[
        'ml-2',
        selectionResultsLoading
          ? 'animate-pulse text-lavender-300'
          : 'text-lavender-600',
      ]"
    />
    <TransitionFade>
      <span class="text-xl font-normal text-deepteal-700"
        >{{
          `${selectionResults ?? "-"} ${texts.navigationItems.mailing.steps.selectionDefinition.title}
          `
        }}
      </span>
    </TransitionFade>
  </div>
</template>

<script setup lang="ts">
import texts from "@/utils/texts";
import { ref } from "vue";
import {
  SelectionDefinitionDto,
  SelectionResultsCountDto,
} from "@/lib/eduConfigurationServiceClient";
import TransitionFade from "@/components/common/transition/TransitionFade.vue";
import Icon from "@/components/common/icon/Icon.vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { watchThrottled } from "@vueuse/core";

const props = defineProps<{
  definition: SelectionDefinitionDto;
}>();

const emit = defineEmits<{
  (e: "results", value: SelectionResultsCountDto | undefined): void;
}>();

const selectionResultsLoading = ref(false);
const selectionResults = ref<number>();

const getProspectCount = async (definition: SelectionDefinitionDto) => {
  selectionResultsLoading.value = true;

  try {
    const response =
      await eduConfigurationServiceClient.getSelectionResultCountWithDistinctProspectCount(
        definition,
      );
    selectionResults.value = response?.prospectCount;
    emit("results", response);
  } finally {
    selectionResultsLoading.value = false;
  }
};

watchThrottled(() => props.definition, getProspectCount, {
  throttle: 3000,
  immediate: true,
});
</script>
