<template>
  <FormLayout @submit="onSubmit">
    <LocalizationTabs
      v-slot="{ current }"
      :locales
      @add="addLocalization"
      @remove="removeLocalization"
    >
      <LocalizationSlot v-slot="{ locale }" :current :locales>
        <FormFieldTextInput
          :id="`localizations.${locale}.name`"
          :label="`${txtSession.localizations.name} (${
            texts.enums.culture[locale]
          })`"
        />
      </LocalizationSlot>
      <StudyProgramFormField
        id="studyProgramIds"
        :label="txtSession.studyPrograms"
        :filteredStudyProgramIds="studyProgramIds"
        :disabled="form.values.groupId !== undefined"
      />
      <FormFieldSelect
        id="groupId"
        :label="txtSession.group"
        :items="groupOptions"
        :disabled="(form.values.studyProgramIds?.length ?? 0) > 0"
        allowUnset
      />
      <RegistrationLinkFormField
        id="registrationLinkIds"
        :options="registrationLinks"
        :label="txtSession.registrationLink"
        allowUnset
      />
      <h2 class="text-lg font-semibold text-deepteal-700">
        {{ txtForm.timeAndLocation }}
      </h2>
      <div class="grid grid-cols-1 items-start gap-3 xl:grid-cols-2">
        <FormFieldDateTime
          id="startDateTime"
          :label="txtSession.startDateTime"
          :min="activityStartDateTime"
          :max="activityEndDateTime"
        />
        <FormFieldDateTime
          id="endDateTime"
          :label="txtSession.endDateTime"
          :min="activityStartDateTime"
          :max="activityEndDateTime"
        />
      </div>
      <div class="flex items-start gap-3">
        <FormFieldToggle id="online" :disabled="props.isActivityOnline" />
        <span
          :class="[
            'text-sm',
            {
              'font-bold': form.values.online === true,
            },
          ]"
          >{{ texts.enums.sessionMode[SessionMode.Online] }}</span
        >
      </div>
      <template v-if="form.values.online === true">
        <FormFieldTextInput
          id="onlineSessionUrl"
          :label="txtSession.onlineSessionUrl"
          :type="TextInputType.URL"
        />
      </template>
      <template v-else>
        <FormFieldSelect
          id="locationId"
          :label="txtSession.location"
          :items="locationOptions"
          allowUnset
        />
        <FormFieldTextInput id="room" :label="txtSession.room" />
      </template>
      <div class="grid grid-cols-1 xl:grid-cols-3">
        <FormFieldTextInput
          id="capacity"
          :label="txtSession.capacity"
          :type="TextInputType.NUMBER"
        />
      </div>
      <h2 class="text-lg font-semibold text-deepteal-700">
        {{ txtForm.extraInformation }}
      </h2>
      <LocalizationSlot v-slot="{ locale }" :current :locales>
        <FormFieldWysiwyg
          :id="`localizations.${locale}.descriptionForRegistrationForm`"
          :label="`${txtSession.localizations.descriptionForRegistrationForm} (${
            texts.enums.culture[locale]
          })`"
        />
        <FormFieldWysiwyg
          :id="`localizations.${locale}.descriptionForRegistrationConfirmation`"
          :label="`${
            txtSession.localizations.descriptionForRegistrationConfirmation
          } (${texts.enums.culture[locale]})`"
        />
      </LocalizationSlot>
      <SessionFormSection
        v-model:expanded="sections.advanced"
        :title="txtForm.advanced"
      >
        <FormFieldSelect
          id="typeId"
          :label="txtSession.type"
          :items="typeOptions"
          allowUnset
        />
        <FormFieldTextInput
          id="contactName"
          :label="txtSession.contactName"
          :type="TextInputType.TEXT"
        />
        <FormFieldTextInput
          id="notificationsEmail"
          :label="txtSession.notificationEmail"
          :type="TextInputType.EMAIL"
        />
        <FormFieldToggle
          id="notificationsEnabled"
          :label="txtSession.notificationEnabled"
        />
        <FormFieldTextInput
          id="externalReference"
          :label="txtSession.externalReference"
        />
        <div class="grid grid-cols-1 items-start gap-3 xl:grid-cols-2">
          <FormFieldDateTime
            id="registrationFromDateTime"
            :label="txtSession.registerFromDateTime"
            :max="activityEndDateTime"
          />
          <FormFieldDateTime
            id="registrationUntilDateTime"
            :label="txtSession.registerUntilDateTime"
            :max="activityEndDateTime"
          />
        </div>
        <div class="grid grid-cols-1 xl:grid-cols-3">
          <FormFieldTextInput
            id="sequenceNumber"
            :label="txtSession.sequenceNumber"
            :type="TextInputType.NUMBER"
          />
        </div>
      </SessionFormSection>
    </LocalizationTabs>
    <template v-slot:actions><slot></slot></template>
  </FormLayout>
</template>

<script setup lang="ts">
import { useForm } from "vee-validate";
import * as yup from "yup";
import texts from "@/utils/texts";

import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import StudyProgramFormField from "@/components/study-programs/StudyProgramFormField.vue";

import {
  SessionFormValues,
  SessionFormValuesLocalizations,
} from "./SessionForm.types";
import FormFieldDateTime from "@/components/common/datetime/FormFieldDateTime.vue";
import FormFieldSelect from "@/components/common/select/FormFieldSelect.vue";
import { computed, reactive, watch } from "vue";
import { SelectOption } from "@/components/common/select/SelectOption";
import { TextInputType } from "@/components/common/text-input/TextInput.types";
import { Culture, EmailTemplateType } from "@/enums";
import FormFieldWysiwyg from "@/components/common/wysiwyg/FormFieldWysiwyg.vue";
import FormFieldToggle from "@/components/common/toggle/FormFieldToggle.vue";
import SessionGroup from "@/models/sessionGroup";
import SessionType from "@/models/sessionType";
import SessionFormSection from "./SessionFormSection.vue";
import { SessionMode } from "@/models/session";
import { DateTime } from "luxon";
import FormLayout from "@/components/common/form/FormLayout.vue";
import RegistrationLinkFormField from "@/views/organize/activities/activity/sessions/components/session-form/RegistrationLinkFormField.vue";
import settings from "@/store/context/settings.context";
import {
  ActivityLocationLocalizedDTO,
  RegistrationLinkWithRegistrationCountDTO,
} from "@/lib/eduConfigurationServiceClient";
import LocalizationTabs from "@/components/localization-tabs/LocalizationTabs.vue";
import LocalizationSlot from "@/components/localization-tabs/LocalizationSlot.vue";

const props = defineProps<{
  formValues?: SessionFormValues;
  activityStartDateTime: DateTime;
  activityEndDateTime: DateTime;
  isActivityOnline: boolean;
  locations: ActivityLocationLocalizedDTO[];
  groups: SessionGroup[];
  types: SessionType[];
  registrationLinks: RegistrationLinkWithRegistrationCountDTO[];
  studyProgramIds: string[] | undefined;
}>();

const emit = defineEmits<{
  (e: "submit", values: SessionFormValues): void;
}>();

const txtSession = texts.models.session;
const txtForm = texts.navigationItems.organize.activity.sessions.form;

const notificationEmailTemplateExists = () => {
  return (
    settings.emailTemplates.filter(
      (template) =>
        template.type ===
        EmailTemplateType.SessionRegistrationStatusNotification,
    ).length > 0
  );
};

const localizationsSchema = yup.object<SessionFormValuesLocalizations>(
  settings.availableLanguages.reduce(
    (acc, language) => ({
      ...acc,
      [language.locale.value as Culture]: yup.lazy((value) =>
        value
          ? yup.object({
              name: yup.string().max(256).required(),
              descriptionForRegistrationForm: yup.string(),
              descriptionForRegistrationConfirmation: yup.string(),
            })
          : yup.mixed(),
      ),
    }),
    {},
  ),
);
// Form data
const validationSchema = computed(() =>
  yup.object().shape(
    {
      localizations: localizationsSchema,
      studyProgramIds: yup.array().when("groupId", {
        is: (val: string | undefined) => val === undefined,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema,
      }),
      groupId: yup.string().when("studyProgramIds", {
        is: (val: string[] | undefined) =>
          val === undefined || val.length === 0,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema,
      }),
      registrationLinkIds: yup.array().of(yup.string()).min(1).required(),
      startDateTime: yup
        .mixed()
        .required()
        .isLaterThan(props.activityStartDateTime, texts.validation.outOfRange)
        .isEarlierThan(props.activityEndDateTime, texts.validation.outOfRange),
      endDateTime: yup
        .mixed()
        .required()
        .isLaterThan(props.activityStartDateTime, texts.validation.outOfRange)
        .isEarlierThan(props.activityEndDateTime, texts.validation.outOfRange)
        .isLaterThan("startDateTime", texts.validation.dateBeforeStartDateTime),
      online: yup
        .boolean()
        .default(props.isActivityOnline)
        .test(
          "isActivityOnline",
          texts.validation.sessionShouldBeOnline,
          (value) => !props.isActivityOnline || value === true,
        ),
      onlineSessionUrl: yup.string().when("online", {
        is: true,
        then: (schema) => schema.url().required(),
      }),
      locationId: yup.string(),
      room: yup.string(),
      contactName: yup.string().max(128),
      notificationsEmail: yup.string().email(),
      notificationsEnabled: yup.boolean().when("notificationsEmail", {
        is: (email: string) => email && email.length > 0,
        then: (schema) =>
          schema.test({
            name: "template-exists",
            test: function (value) {
              return (
                value === false || // Always allow disabling
                notificationEmailTemplateExists() ||
                this.createError({
                  message:
                    texts.validation.sessionNotificationsRequireEmailTemplate,
                })
              );
            },
          }),
        otherwise: (schema) =>
          schema.oneOf(
            [false],
            texts.validation.sessionNotificationsRequireEmailAddress,
          ),
      }),
      capacity: yup.number(),
      typeId: yup.string(),
      externalReference: yup.string().max(128),
      sequenceNumber: yup.number(),
      registrationFromDateTime: yup
        .mixed()
        .isEarlierThan(props.activityEndDateTime, texts.validation.outOfRange),
      registrationUntilDateTime: yup
        .mixed()
        .isEarlierThan(props.activityEndDateTime, texts.validation.outOfRange),
    },
    [["studyProgramIds", "groupId"]], //This exclusion counters cyclic yup dependencies
  ),
);

const locales = computed(
  () => Object.keys(form.values.localizations) as Culture[],
);

function addLocalization(culture: Culture) {
  form.setFieldValue("localizations", {
    ...form.values.localizations,
    [culture]: { name: "" },
  });
}

function removeLocalization(culture: Culture) {
  const newDict = { ...form.values.localizations };
  delete newDict[culture];
  form.setFieldValue("localizations", newDict);
}

const form = useForm<SessionFormValues>({
  validationSchema,
  initialValues: {
    startDateTime: props.activityStartDateTime,
    endDateTime: props.activityEndDateTime,
    online: props.isActivityOnline ?? false,
    localizations: {},
  },
});

const onSubmit = form.handleSubmit((values) => {
  emit("submit", values);
});

watch(
  () => props.formValues,
  (values) => {
    form.setValues({
      ...values,
      online: props.isActivityOnline === true ? true : values?.online,
    });
  },
  { immediate: true },
);

const groupOptions = computed<SelectOption[]>(() =>
  props.groups.map((group) => ({
    label: group.name,
    value: group.id,
  })),
);

const locationOptions = computed<SelectOption[]>(() =>
  props.locations.map((location) => ({
    label: location.name,
    value: location.id,
  })),
);

const typeOptions = computed<SelectOption[]>(() =>
  props.types.map((type) => ({
    label: type.name,
    value: type.id,
  })),
);

const sections = reactive({
  advanced:
    !props.formValues ||
    !!(
      props.formValues.typeId ||
      props.formValues.externalReference ||
      props.formValues.registrationFromDateTime ||
      props.formValues.registrationUntilDateTime ||
      props.formValues.sequenceNumber
    ),
});
</script>
