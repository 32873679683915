<template>
  <FormFieldMultiCriterion
    :id
    :options
    :initialValue="
      criterion.preEducationLevelIds
        ? [...criterion.preEducationLevelIds]
        : undefined
    "
    :disabled="readonly || criterion.isReadOnly"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { TypedCriterionFieldProps } from "@/components/selection/SelectionCriterionForm.types";
import { fields } from "@/utils/miscellaneous";
import { SelectionCriterionDto } from "@/lib/eduConfigurationServiceClient";
import settings from "@/store/context/settings.context";
import FormFieldMultiCriterion from "@/components/selection/type/components/FormFieldMultiCriterion.vue";

const props = defineProps<TypedCriterionFieldProps>();

const id = fields<SelectionCriterionDto>().preEducationLevelIds;

const options = computed(() =>
  props.context.preEducationLevels.map((level) => ({
    id: level.id,
    label:
      level.localizations.find((l) => l.locale === settings.culture)?.name ??
      level.localizations[0].name,
  })),
);
</script>
