<template>
  <div
    v-if="icon"
    :class="[
      'flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full',
      {
        'bg-alert-50': type === ModalType.danger,
        'bg-warning-50': type === ModalType.warning,
        'bg-success-50': type === ModalType.success,
      },
    ]"
  >
    <Icon
      :icon
      :class="[
        {
          'text-alert-600': type === ModalType.danger,
          'text-warning-600': type === ModalType.warning,
          'text-success-600': type === ModalType.success,
        },
      ]"
      aria-hidden="true"
      :size="IconSize.md"
    />
  </div>
</template>

<script setup lang="ts">
import { IconSize } from "@/components/common/icon/Icon.types";
import Icon from "@/components/common/icon/Icon.vue";
import { ModalType } from "@/components/common/modal/Modal.types";
import { computed } from "vue";

const props = defineProps<{
  type: ModalType;
}>();

const icon = computed(() => {
  switch (props.type) {
    case ModalType.danger:
      return "warning";
    case ModalType.warning:
      return "warning";
    case ModalType.success:
      return "check";
    default:
      return undefined;
  }
});
</script>
