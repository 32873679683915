<template>
  <FormLayout
    class="min-w-96 rounded-lg bg-white pt-4 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
    @submit.prevent="onSubmit"
  >
    <template v-slot:header>
      <button
        class="self-end px-4 sm:px-6"
        type="button"
        @click="emit('cancel')"
      >
        <Icon icon="close" :size="IconSize.sm" :color="Color.Gray" clickable />
      </button>
    </template>
    <div class="flex flex-col gap-1">
      <FormFieldDate
        id="start"
        :label="componentTexts.filters.period.start"
        :displayMode="FormFieldDisplayMode.Row"
        :min="DateTime.now().minus({ years: 10 })"
        :max="DateTime.now().plus({ years: 2 })"
      />

      <FormFieldDate
        id="end"
        :label="componentTexts.filters.period.end"
        :displayMode="FormFieldDisplayMode.Row"
      />
    </div>
    <div class="flex flex-col gap-1">
      <Button
        v-if="recruitmentYear"
        :size="ButtonSize.sm"
        :color="Color.White"
        :leadingIcon="'calendar_month'"
        :label="componentTexts.filters.period.current"
        class="text-nowrap"
        @click="form.setValues(toCurrentRecruitmentYearDates(recruitmentYear))"
      />
      <Button
        v-if="recruitmentYear"
        :size="ButtonSize.sm"
        :color="Color.White"
        :leadingIcon="'calendar_month'"
        :label="componentTexts.filters.period.previous"
        :data-testid="testIds.dashboard.selectionPeriodFilter.previous"
        class="text-nowrap"
        @click="form.setValues(toPreviousRecruitmentYearDates(recruitmentYear))"
      />
    </div>
    <template v-if="!apply" v-slot:actions>
      <div class="flex flex-row gap-4">
        <button
          class="cursor-pointer text-sm font-medium text-lavender-500 hover:text-lavender-700"
          type="submit"
          :data-testid="testIds.action.submit"
        >
          {{ texts.actions.apply }}
        </button>
        <button
          class="cursor-pointer text-sm font-medium text-lavender-500 hover:text-lavender-700"
          type="button"
          @click="onCancel"
        >
          {{ texts.actions.cancel }}
        </button>
      </div>
    </template>
  </FormLayout>
</template>

<script setup lang="ts">
import { ButtonSize } from "@/components/common/button/Button.types";
import Button from "@/components/common/button/Button.vue";
import FormFieldDate from "@/components/common/date/FormFieldDate.vue";
import FormLayout from "@/components/common/form/FormLayout.vue";
import { Color } from "@/enums.js";
import { RecruitmentYearDTO } from "@/lib/eduConfigurationServiceClient";
import texts from "@/utils/texts";
import { DateTime } from "luxon";
import { useForm } from "vee-validate";
import { watch } from "vue";
import * as yup from "yup";
import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import { testIds } from "@/utils/testing";
import Icon from "@/components/common/icon/Icon.vue";
import { IconSize } from "@/components/common/icon/Icon.types";

const componentTexts = texts.navigationItems.mailing.steps.selectionDefinition;

const props = withDefaults(
  defineProps<{
    recruitmentYear: RecruitmentYearDTO;
    formValues: Partial<SelectionPeriodFormValues>;
    apply?: boolean;
  }>(),
  {
    apply: false,
  },
);

const emit = defineEmits<{
  submit: [values: SelectionPeriodFormValues];
  cancel: [];
}>();

export type SelectionPeriodFormValues = {
  start: DateTime;
  end: DateTime;
};

const form = useForm<SelectionPeriodFormValues>({
  validationSchema: yup.object({
    start: yup
      .mixed<DateTime>()
      .required()
      .isEarlierThan(
        DateTime.now().plus({ years: 2 }),
        texts.validation.outOfRange,
      )
      .isLaterThan(
        DateTime.now().minus({ years: 10 }),
        texts.validation.outOfRange,
      ),
    end: yup
      .mixed<DateTime>()
      .required()
      .isLaterThan("start", texts.validation.endDateTimeMinimumStartDateTime),
  }),
});

function toCurrentRecruitmentYearDates(
  recruitmentYear: RecruitmentYearDTO,
): SelectionPeriodFormValues {
  const currentDate = DateTime.now();

  let start = DateTime.fromObject({
    year: currentDate.year,
    month: recruitmentYear.monthStart,
    day: recruitmentYear.dayStart,
  });

  // eg. in January 2025, current recruitment year is still 2024-2025 until start-date
  // so we need to set start and end to the previous year
  if (currentDate < start) {
    start = start.minus({ year: 1 });
  }

  return {
    start,
    end: start.plus({ year: 1 }).minus({ day: 1 }),
  };
}

function toPreviousRecruitmentYearDates(
  recruitmentYear: RecruitmentYearDTO,
): SelectionPeriodFormValues {
  const current = toCurrentRecruitmentYearDates(recruitmentYear);

  return {
    start: current.start.minus({ year: 1 }),
    end: current.end.minus({ year: 1 }),
  };
}

const onSubmit = () => {
  form.handleSubmit(() => {
    emit("submit", form.values);
  })();
};

const onCancel = () => {
  form.resetForm({
    values: {
      start: props.formValues.start,
      end: props.formValues.end,
    },
  });
  emit("cancel");
};

form.setValues({
  start: props.formValues.start,
  end: props.formValues.end,
});

watch(form.values, () => {
  if (props.apply) {
    onSubmit();
  }
});
</script>
