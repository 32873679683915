<template>
  <Tag :color :size :icon>
    <slot></slot>
    <button v-if="!disabled" type="button" @click="emit('remove')">
      <Icon
        icon="close"
        :size="IconSize.xs"
        :color="color"
        aria-hidden="true"
      />
    </button>
  </Tag>
</template>

<script setup lang="ts">
import { Color } from "@/enums";
import Tag from "@/components/common/tag/Tag.vue";
import Icon from "@/components/common/icon/Icon.vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import { TagSize } from "./Tag.types";
import { IconNames } from "@/components/common/icon/Icon.types";

defineProps<{
  color?: Color;
  size?: TagSize;
  icon?: IconNames;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  remove: [];
}>();
</script>
