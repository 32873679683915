<template>
  <SlideOver
    v-model:visible="visibleInternal"
    size="xl"
    :title="targetAudience?.name"
  >
    <Loader v-if="!targetAudience" />
    <TargetAudienceForm
      v-if="targetAudience"
      :targetAudience="targetAudience"
      :selectionContext="selectionContext"
      @submit="handleSubmit"
    >
      <ButtonSubmit>
        {{ texts.actions.submit }}
      </ButtonSubmit>
      <ButtonCancel @click="visibleInternal = false">
        {{ texts.actions.cancel }}
      </ButtonCancel>
      <ButtonDelete @click="handleDeleteClick">
        {{ texts.actions.delete }}
      </ButtonDelete>
    </TargetAudienceForm>
  </SlideOver>
</template>

<script setup lang="ts">
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import Loader from "@/components/common/loader/Loader.vue";
import { computed, ref, watchEffect } from "vue";
import TargetAudienceForm, {
  TargetAudienceSubmitValues,
} from "@/views/settings/organization/target-audiences/TargetAudienceForm.vue";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";
import {
  TargetAudienceDTO,
  ValidationErrorResponse,
} from "@/lib/eduConfigurationServiceClient";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import ButtonDelete from "@/components/common/button/ButtonDelete.vue";
import logger from "@/plugins/logger";
import { CriterionFieldContext } from "@/components/selection/SelectionCriterionForm.context";

const componentTexts = texts.navigationItems.organize.targetAudiences.edit;

const props = defineProps<{
  targetAudienceId?: string;
  selectionContext: CriterionFieldContext;
}>();

const emit = defineEmits<{
  "update:targetAudienceId": [value: string | undefined];
  "targetAudiences:stale": [];
}>();

const visibleInternal = computed({
  get() {
    return props.targetAudienceId !== undefined;
  },
  set(value) {
    if (!value) {
      targetAudience.value = undefined;
      emit("update:targetAudienceId", undefined);
    }
  },
});

const targetAudience = ref<TargetAudienceDTO>();
watchEffect(() => {
  if (props.targetAudienceId) {
    eduConfigurationServiceClient
      .getTargetAudienceById(props.targetAudienceId)
      .then((response) => {
        targetAudience.value = response;
      })
      .catch((e) => {
        logger.error(e);

        Notify.failure(componentTexts.loadingFailure);
      });
  }
});

const handleDeleteClick = () => {
  if (!props.targetAudienceId) {
    throw new Error("Target audience id is undefined");
  }

  eduConfigurationServiceClient
    .deleteTargetAudience(props.targetAudienceId)
    .then(() => {
      Notify.success(componentTexts.deleteSuccess);

      emit("targetAudiences:stale");
      visibleInternal.value = false;
    })
    .catch(handleDeleteError);
};

function handleDeleteError(error: unknown) {
  logger.error(error);

  if (error instanceof ValidationErrorResponse) {
    Notify.failure(texts.validationErrors[error.errorCode]);
    return;
  }

  Notify.failure(componentTexts.deleteFailure);
}

const handleSubmit = (values: TargetAudienceSubmitValues) => {
  if (!props.targetAudienceId) {
    throw new Error("Target audience id is undefined");
  }

  const dto = new TargetAudienceDTO({
    id: props.targetAudienceId,
    name: values.name,
    description: values.description,
    selectionDefinition: values.selectionDefinition,
  });

  eduConfigurationServiceClient
    .updateTargetAudience(props.targetAudienceId, dto)
    .then(() => {
      Notify.success(componentTexts.updateSuccess);

      emit("targetAudiences:stale");
      visibleInternal.value = false;
    })
    .catch(() => {
      Notify.failure(componentTexts.updateFailure);
    });
};
</script>
