import { Culture } from "@/enums";
import TargetAudience from "@/models/targetAudience";
import { LocalizedQuestionWithDiscreteAnswers } from "@/lib/formsServiceClient";
import {
  LocalizedActivityDTO,
  MailingSelectionDefinitionDto,
  PreEducationLevelDTO,
  SelectionDefinitionDto,
  ThirdPartyProspectSourceDto,
} from "@/lib/eduConfigurationServiceClient";
import { SessionsForActivity } from "@/models/session";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { formsServiceClient } from "@/services/formsService.client.service";
import settings from "@/store/context/settings.context";

export type CriterionFieldContext = {
  activities: LocalizedActivityDTO[];
  targetAudiences: TargetAudience[];
  languages: Culture[];
  questionsWithAnswers: LocalizedQuestionWithDiscreteAnswers[];
  sessions: SessionsForActivity;
  mailings: MailingSelectionDefinitionDto[];
  preEducationLevels: PreEducationLevelDTO[];
  thirdPartyProspectSources: ThirdPartyProspectSourceDto[];
};

export const loadCriterionFieldContext = async (
  activityId?: string,
): Promise<CriterionFieldContext> => {
  const [
    activityDtos,
    targetAudienceDtos,
    questionDtos,
    mailingDtos,
    preEducationLevels,
    thirdPartyProspectSourceIds,
  ] = await Promise.all([
    eduConfigurationServiceClient.getActivities(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    ),
    eduConfigurationServiceClient.getTargetAudiences(),
    formsServiceClient.getQuestionsWithDiscreteAnswers(),
    eduConfigurationServiceClient.getMailingsForSelectionDefinitions(),
    eduConfigurationServiceClient.getAllPreEducationLevels(),
    eduConfigurationServiceClient.getAllThirdPartyProspectSources(),
  ]);

  return {
    activities: activityDtos,
    targetAudiences: targetAudienceDtos,
    languages: settings.availableLanguages.map(
      (language) => language.locale.value as Culture,
    ),
    questionsWithAnswers: questionDtos,
    mailings: mailingDtos,
    preEducationLevels: preEducationLevels.levels,
    sessions: {
      activityId: activityId,
      sessions: activityId
        ? await eduConfigurationServiceClient.getSessions(activityId)
        : [],
    },
    thirdPartyProspectSources: thirdPartyProspectSourceIds,
  };
};

export function fetchSelectionResults(definiton: SelectionDefinitionDto) {
  if (definiton.criteria.length > 0) {
    return eduConfigurationServiceClient.getSelectionResultCountWithDistinctProspectCount(
      definiton,
    );
  }
}
