import { CriterionFieldContext } from "@/components/selection/SelectionCriterionForm.context";
import AnswerCriterionField from "@/components/selection/type/AnswerCriterionField.vue";
import LanguageCriterionField from "@/components/selection/type/LanguageCriterionField.vue";
import MailingCriterionField from "@/components/selection/type/MailingCriterionField.vue";
import MultiActivityCriterionField from "@/components/selection/type/MultiActivityCriterionField.vue";
import PreEducationCriterionField from "@/components/selection/type/PreEducationCriterionField.vue";
import SessionCriterionField from "@/components/selection/type/SessionCriterionField.vue";
import StudyProgramCriterionField from "@/components/selection/type/StudyProgramCriterionField.vue";
import TargetAudienceCriterionField from "@/components/selection/type/TargetAudienceCriterionField.vue";
import ThirdPartyProspectSourceCriterionField from "@/components/selection/type/ThirdPartyProspectSourceCriterionField.vue";
import {
  SelectionCriterionDtoType,
  SelectionCriterionDto,
  SelectionFiltersDto,
} from "@/lib/eduConfigurationServiceClient";

export type TypedCriterionFieldProps = {
  criterion: SelectionCriterionDto;
  context: CriterionFieldContext;
  filters: SelectionFiltersDto;
  readonly: boolean;
};

export const typeToComponentMapper = new Map<
  SelectionCriterionDtoType,
  unknown
>([
  [
    SelectionCriterionDtoType.IsRegisteredForActivity,
    MultiActivityCriterionField,
  ],
  [
    SelectionCriterionDtoType.IsNotRegisteredForActivity,
    MultiActivityCriterionField,
  ],
  [SelectionCriterionDtoType.HasVisitedActivity, MultiActivityCriterionField],
  [
    SelectionCriterionDtoType.HasNotVisitedActivity,
    MultiActivityCriterionField,
  ],
  [
    SelectionCriterionDtoType.NotSubmittedSurveyForVisitedActivity,
    MultiActivityCriterionField,
  ],
  [SelectionCriterionDtoType.IsInTargetAudience, TargetAudienceCriterionField],
  [SelectionCriterionDtoType.IsNotTargetAudience, TargetAudienceCriterionField],
  [SelectionCriterionDtoType.HasLanguage, LanguageCriterionField],
  [SelectionCriterionDtoType.HasGivenAnswer, AnswerCriterionField],
  [SelectionCriterionDtoType.IsRegisteredForSession, SessionCriterionField],
  [
    SelectionCriterionDtoType.IsInterestedInStudyProgram,
    StudyProgramCriterionField,
  ],
  [SelectionCriterionDtoType.HasApplied, StudyProgramCriterionField],
  [SelectionCriterionDtoType.NotReceivedMailing, MailingCriterionField],
  [SelectionCriterionDtoType.HasPreEducation, PreEducationCriterionField],
  [
    SelectionCriterionDtoType.ThirdPartyProspectSource,
    ThirdPartyProspectSourceCriterionField,
  ],
]);

export function isObsoleteCriterion(type: SelectionCriterionDtoType): boolean {
  return (
    type === SelectionCriterionDtoType.ShowedInterestInCurrentYear ||
    type === SelectionCriterionDtoType.IsInterestedInStudyProgram
  );
}

export function criteriaSortFn(
  a: SelectionCriterionDto,
  b: SelectionCriterionDto,
) {
  if (a.isReadOnly === b.isReadOnly) {
    return 0;
  } else if (a.isReadOnly) {
    return -1;
  } else {
    return 1;
  }
}
