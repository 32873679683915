import { MailingType } from "@/enums";
import {
  IActivityInviteMailingDTO,
  IActivityVisitedSurveyMailingDTO,
  PlanningMode,
  SelectionDefinitionDto,
  ActivityInviteMailingDTO,
  LocalizedActivityDTO,
} from "@/lib/eduConfigurationServiceClient";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { DateTime } from "luxon";
import { ConceptMailingData } from "../MailingWizard.types";
import settings from "@/store/context/settings.context";
import { convertToDto } from "@/views/mailings/mailing/steps/Timing.types";

export interface MailingDescriptionListData {
  type?: MailingType;
  localeName: string;
  name?: string;
  activity?: LocalizedActivityDTO;
  activityId?: string;
  activityName?: string;
  registrationLinkId?: string;
  registrationLinkName?: string;
  activityVisitedSurveyLinkId?: string;
  activityVisitedSurveyLinkName?: string;
  activityStartDateTime?: DateTime;
  subject?: string;
  fromName?: string;
  fromEmailAddress?: string;
  replyToEmailAddress?: string;
  mailingContent?: string;
  planningMode?: PlanningMode;
  relativePlanningDayOffset?: number;
  relativePlanningTimeOfDay?: string;
  plannedDateTime?: DateTime;
  selectionDefinition?: SelectionDefinitionDto;
}

export interface MailingDescriptionListProps {
  data: MailingDescriptionListData;
}

export async function getMailingDescriptionListDataFromMailing(
  mailing: ActivityInviteMailingDTO,
): Promise<MailingDescriptionListData> {
  let selectionDefinition: SelectionDefinitionDto | undefined;

  if (mailing.selectionDefinitionId) {
    selectionDefinition =
      await eduConfigurationServiceClient.getSelectionDefinition(
        mailing.selectionDefinitionId,
      );
  }

  const language = settings.availableLanguages.find(
    (lang) => lang.locale.value === mailing.locale,
  )?.name;
  if (!language) {
    throw new Error(`Language with locale ${mailing.locale} not found`);
  }

  let activity: LocalizedActivityDTO | undefined;
  if (mailing.activityId) {
    const activities = await eduConfigurationServiceClient.getActivities(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    activity = activities.find(
      (activity) => activity.id === mailing.activityId,
    );
  }

  const dto: MailingDescriptionListData = {
    type: mailing.type as MailingType,
    localeName: language,
    name: mailing.name,
    activity: activity,
    activityId: mailing.activityId,
    activityName: mailing.activityName,
    subject: mailing.subject,
    fromEmailAddress: mailing.fromEmailAddress,
    fromName: mailing.fromName,
    mailingContent: mailing.mailingContent?.content,
    replyToEmailAddress: mailing.replyToEmailAddress,
    planningMode: mailing.planningMode,
    relativePlanningDayOffset: mailing.relativePlanningDayOffset,
    relativePlanningTimeOfDay:
      mailing.relativePlanningTimeOfDay?.toISOTime({
        includeOffset: false,
      }) ?? undefined,
    plannedDateTime: mailing.plannedDateTime,
    selectionDefinition: selectionDefinition,
  };

  switch (mailing.type) {
    case MailingType.ActivityInvite: {
      const activityInviteMailingDTO = mailing as IActivityInviteMailingDTO;
      return {
        ...dto,
        activityId: activityInviteMailingDTO.activityId,
        activityName: activityInviteMailingDTO.activityName,
        registrationLinkId: activityInviteMailingDTO.registrationLinkId,
        registrationLinkName: activityInviteMailingDTO.registrationLinkName,
      } as MailingDescriptionListData;
    }
    case MailingType.ActivityVisitedSurvey: {
      const activityVisitedSurveyMailingDTO =
        mailing as IActivityVisitedSurveyMailingDTO;
      return {
        ...dto,
        activityId:
          (activityVisitedSurveyMailingDTO.activityId as string) ?? "",
        activityName: activityVisitedSurveyMailingDTO.activityName,
        surveyLinkId:
          activityVisitedSurveyMailingDTO.activityVisitedSurveyLinkId,
        surveyLinkName:
          activityVisitedSurveyMailingDTO.activityVisitedSurveyLinkName,
      } as MailingDescriptionListData;
    }
  }

  return dto;
}

export function getMailingDescriptionListDataFromConcept(
  concept: ConceptMailingData,
): MailingDescriptionListData {
  const language = settings.availableLanguages.find(
    (lang) => lang.locale.value === concept.locale,
  )?.name;
  if (!language) {
    throw new Error(`Language with locale ${concept.locale} not found`);
  }

  const timingDto = concept.timingFormValues
    ? convertToDto(concept.timingFormValues)
    : undefined;
  return {
    type: concept.type,
    localeName: language,
    name: concept.name,
    activity: concept.activity,
    activityId: concept.activity ? concept.activity.id : undefined,
    activityName: concept.activity ? concept.activity.name : undefined,
    activityStartDateTime: concept.activity
      ? concept.activity.startDateTime
      : undefined,
    registrationLinkName: concept.registrationLink?.name,
    activityVisitedSurveyLinkName: concept.surveyLink?.name,
    subject: concept.subject,
    fromName: concept.fromName,
    fromEmailAddress: concept.fromEmailAddress,
    replyToEmailAddress: concept.replyToEmailAddress,
    mailingContent: concept.mailingContent?.content,
    selectionDefinition: concept.selectionDefinition,
    ...timingDto,
  };
}
