<template>
  <div
    v-if="selectedOptions.length"
    class="flex flex-wrap items-center gap-1.5"
  >
    <template v-if="selectedOptions.length <= 10">
      <Tag v-for="option in selectedOptions" :key="option.id">
        {{ option.label }}
      </Tag>
    </template>
    <template v-else>
      <Tag>{{ selectedOptions.length }} {{ texts.models.session.plural }}</Tag>
    </template>
    <button
      v-if="!criterion.isReadOnly && !readonly"
      type="button"
      @click="showAddSessions = !showAddSessions"
    >
      <Icon icon="edit" :size="IconSize.sm" :color="Color.Lavender" clickable />
    </button>
  </div>
  <TransitionMenu>
    <div
      v-if="!sessionIds?.length || showAddSessions"
      :class="[
        'flex flex-col gap-1 rounded-md p-4 shadow-lg ring-1 focus:outline-none',
        errorMessage ? 'ring-alert-500/50' : 'ring-black/5',
      ]"
    >
      <div class="self-end">
        <button @click="showAddSessions = false">
          <Icon
            icon="close"
            :size="IconSize.sm"
            :color="Color.DeepTeal"
            clickable
          />
        </button>
      </div>
      <Search v-model="search" />
      <div class="flex">
        <Dropdown
          v-if="locations.length > 0"
          class="w-full"
          :label="selectedLocation?.name || texts.models.activityLocation.title"
          :type="selectedLocation ? DropdownType.primary : DropdownType.white"
          :origin="DropdownOrigin.TopLeft"
        >
          <DropdownItem
            label="&nbsp;"
            @click="() => (selectedLocation = undefined)"
          ></DropdownItem>
          <DropdownItem
            v-for="location in locations"
            :key="location.id"
            :label="location.name"
            @click="() => (selectedLocation = location)"
          ></DropdownItem>
        </Dropdown>
        <Dropdown
          class="w-full"
          :label="
            selectedDate
              ? selectedDate.toLocaleString(DateTime.DATE_SHORT)
              : texts.models.session.date
          "
          :type="selectedDate ? DropdownType.primary : DropdownType.white"
          :origin="DropdownOrigin.TopLeft"
        >
          <DropdownItem
            label="&nbsp;"
            @click="() => (selectedDate = undefined)"
          ></DropdownItem>
          <DropdownItem
            v-for="date in dates"
            :key="date.toLocaleString(DateTime.DATE_SHORT)"
            :label="date.toLocaleString(DateTime.DATE_SHORT)"
            @click="() => (selectedDate = date)"
          ></DropdownItem>
        </Dropdown>
      </div>

      <div
        v-if="errorMessage"
        class="text-sm text-alert-500 sm:col-start-2"
        data-testid="error-message"
      >
        {{ errorMessage }}
      </div>

      <Table
        data-testid="sessions"
        :class="[errorMessage ? 'border border-alert-500' : '', 'p-1']"
      >
        <template v-slot:header>
          <TableRow>
            <TableHeader class="w-10">
              <Checkbox
                v-model="allChecked"
                data-testid="all-checked"
                name="all-checked"
              ></Checkbox
            ></TableHeader>
            <TableHeader>{{ texts.models.session.title }}</TableHeader>
          </TableRow>
        </template>

        <template v-for="(group, id) in sessionsByGroup" :key="id">
          <TableRow>
            <TableColumn wrap :colspan="2">
              {{ groups?.find((group) => group.id === id)?.name }}
            </TableColumn>
          </TableRow>
          <TableRow
            v-for="session in group"
            :key="session.id"
            data-testid="sessions-row"
            :selected="isSelected(session.id)"
          >
            <TableColumn :stroke="isSelected(session.id)">
              <Checkbox
                :id="session.id"
                v-model="sessionIds"
                data-testid="sessions-checked"
                name="sessions-checked"
                :value="session.id"
              ></Checkbox>
            </TableColumn>
            <TableColumn
              wrap
              accent
              data-testid="sessions-label"
              :selected="isSelected(session.id)"
              ><label :for="session.id">
                {{
                  `${session.name} (${Interval.fromDateTimes(
                    session.startDateTime,
                    session.endDateTime,
                  ).toLocaleString(DateTime.DATETIME_MED)})`
                }}
              </label>
            </TableColumn>
          </TableRow>
        </template>

        <template
          v-for="(studyPrograms, id) in sessionsByStudyProgram"
          :key="id"
        >
          <TableRow>
            <TableColumn wrap :colspan="2">
              {{ getStudyProgramById(id)?.displayName }}
            </TableColumn>
          </TableRow>
          <TableRow
            v-for="session in studyPrograms"
            :key="`${id}-${session.id}`"
            data-testid="sessions-row"
            :selected="isSelected(session.id)"
          >
            <TableColumn :stroke="isSelected(session.id)">
              <Checkbox
                :id="session.id"
                v-model="sessionIds"
                data-testid="sessions-checked"
                name="sessions-checked"
                :value="session.id"
              ></Checkbox>
            </TableColumn>
            <TableColumn
              wrap
              accent
              data-testid="sessions-label"
              :selected="isSelected(session.id)"
              ><label :for="session.id">{{
                `${session.name} (${Interval.fromDateTimes(
                  session.startDateTime,
                  session.endDateTime,
                ).toLocaleString(DateTime.DATETIME_MED)})`
              }}</label>
            </TableColumn>
          </TableRow>
        </template>
      </Table>
    </div>
  </TransitionMenu>
</template>

<script setup lang="ts">
import { computed, ref, shallowRef, watch } from "vue";
import Checkbox from "@/components/common/checkbox/Checkbox.vue";
import Dropdown from "@/components/common/dropdown/Dropdown.vue";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import Search from "@/components/common/search/Search.vue";
import Table from "@/components/common/table/Table.vue";
import TableColumn from "@/components/common/table/TableColumn.vue";
import TableHeader from "@/components/common/table/TableHeader.vue";
import TableRow from "@/components/common/table/TableRow.vue";

import {
  DropdownOrigin,
  DropdownType,
} from "@/components/common/dropdown/Dropdown.types";
import {
  ActivityLocationLocalizedDTO,
  LocalizedSessionGroupDto,
  SelectionCriterionDto,
  SessionDto,
} from "@/lib/eduConfigurationServiceClient";
import logger from "@/plugins/logger";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { useField } from "vee-validate";
import { TypedCriterionFieldProps } from "@/components/selection/SelectionCriterionForm.types";
import * as yup from "yup";
import { groupBy } from "@/utils/array";
import { fields } from "@/utils/miscellaneous";
import settings from "@/store/context/settings.context";
import { DateTime, Interval } from "luxon";
import texts from "@/utils/texts";
import Tag from "@/components/common/tag/Tag.vue";
import Icon from "@/components/common/icon/Icon.vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import { Color } from "@/enums";
import TransitionMenu from "@/components/common/transition/TransitionMenu.vue";

const props = defineProps<TypedCriterionFieldProps>();

// Filters
const search = ref<string | undefined>(undefined);
const selectedLocation = shallowRef<ActivityLocationLocalizedDTO>();
const selectedDate = shallowRef<DateTime>();

// Other metadata
const loadingMetadata = ref(true);
const errorLoadingMetadata = ref(false);
const groups = ref<LocalizedSessionGroupDto[]>([]);

const fieldId = fields<SelectionCriterionDto>().sessionIds;

const { value: sessionIds, errorMessage } = useField<
  typeof props.criterion.sessionIds
>(fieldId, yup.array().of(yup.string()).required().min(1), {
  initialValue: [...(props.criterion.sessionIds ?? [])], // Shallow-copy to decouple field-array from the original
});

watch(
  () => props.context.sessions,
  (sessionsForActivity) => {
    if (!sessionsForActivity.activityId)
      throw new Error("No activityId provided.");

    Promise.all([
      eduConfigurationServiceClient.getSessionGroups(
        sessionsForActivity.activityId,
      ),
    ])
      .then(([groupDtos]) => {
        groups.value = groupDtos;
      })
      .catch((e) => {
        errorLoadingMetadata.value = true;
        logger.log(e);
      })
      .finally(() => (loadingMetadata.value = false));
  },
  { immediate: true },
);

const locations = computed(() =>
  settings.activityLocations.filter((l) =>
    props.context.sessions.sessions.some((s) => s.locationId === l.id),
  ),
);

const dates = computed(() => {
  const uniqueTimestamps = new Set(
    props.context.sessions.sessions.map((s) =>
      s.startDateTime.startOf("day").toMillis(),
    ),
  );
  return Array.from(uniqueTimestamps)
    .toSorted((a, b) => a - b)
    .map((timestamp) => DateTime.fromMillis(timestamp));
});

const filteredSessions = computed<SessionDto[]>(() =>
  props.context.sessions.sessions
    .filter((session) => {
      const query = search.value?.toLowerCase();
      if (!query) return true;

      let searchableText = "";
      searchableText += session.name;
      searchableText += session.studyProgramIds
        .map(
          (id) =>
            settings.allStudyPrograms.find((sp) => sp.id === id)?.displayName ??
            "",
        )
        .join("");

      if (session.groupId) {
        searchableText +=
          groups.value.find((group) => group.id === session.groupId)?.name ??
          "";
      }

      return searchableText.toLowerCase().includes(query);
    })
    .filter(
      (session) =>
        selectedLocation.value === undefined ||
        session.locationId === selectedLocation.value.id,
    )
    .filter(
      (session) =>
        selectedDate.value === undefined ||
        session.startDateTime.startOf("day").toMillis() ===
          selectedDate.value.toMillis(),
    ),
);

const sessionsByGroup = computed(() => {
  return groupBy(
    filteredSessions.value.filter((session) => !!session.groupId),
    (session) => session.groupId,
  );
});

const sessionsByStudyProgram = computed(() => {
  return groupBy(
    filteredSessions.value.filter(
      (session) =>
        !!session.studyProgramIds && session.studyProgramIds.length > 0,
    ),
    (session) => session.studyProgramIds,
  );
});

const getStudyProgramById = (id: string) => {
  return settings.allStudyPrograms.find((sp) => sp.id === id);
};

const isSelected = (id: string) => {
  if (!sessionIds.value) return false;

  return sessionIds.value.includes(id);
};

// All Sessions selected computed value
const allChecked = computed<boolean>({
  get: () => filteredSessions.value.every((fs) => isSelected(fs.id)),
  set: (newValue) => {
    if (newValue) {
      sessionIds.value = filteredSessions.value.map((fs) => fs.id);
    } else {
      sessionIds.value = sessionIds.value?.filter(
        (id) => !filteredSessions.value.map((fs) => fs.id).includes(id),
      );
    }
  },
});

const options = computed(() => {
  return props.context.sessions.sessions.map((session) => ({
    id: session.id,
    label: session.name,
  }));
});

const selectedOptions = computed(() =>
  options.value
    .filter((option) => sessionIds.value?.includes(option.id))
    .filter((option) => option !== undefined),
);

const showAddSessions = ref(false);

watch(
  sessionIds,
  () => {
    if (!sessionIds.value || sessionIds.value.length === 0) {
      showAddSessions.value = true;
    }
  },
  { immediate: true },
);
</script>
