<template>
  <form
    class="flex h-full min-w-96 flex-col gap-4 rounded-lg bg-white px-4 py-4 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:min-h-[32rem] sm:w-[37rem] sm:px-6"
    @submit.prevent="onSubmit"
  >
    <button class="self-end" type="button" @click="emit('cancel')">
      <Icon icon="close" :size="IconSize.sm" :color="Color.Gray" clickable />
    </button>

    <template v-if="activity">
      <Card class="flex flex-row items-center gap-2" :color="Color.Gray" flat>
        <Checkbox id="useActivityFilter" v-model="useActivityFilter"></Checkbox>
        <label
          for="useActivityFilter"
          class="flex items-center gap-1.5 text-sm font-medium text-gray-700"
        >
          {{ componentTexts.filters.studyPrograms.fromActivity }}
          <Tag :color="Color.Lavender">{{ activity.name }}</Tag></label
        >
      </Card>
    </template>
    <div :class="{ 'pointer-events-none opacity-40': useActivityFilter }">
      <FormFieldStudyProgramPicker id="studyProgramIds" :studyPrograms />
    </div>
    <template v-if="!apply">
      <Divider />
      <div class="flex flex-row gap-4">
        <button
          class="cursor-pointer text-sm font-medium text-lavender-500 hover:text-lavender-700"
          type="submit"
          :data-testid="testIds.action.confirm"
        >
          {{ texts.actions.apply }}
        </button>
        <button
          class="cursor-pointer text-sm font-medium text-lavender-500 hover:text-lavender-500"
          type="button"
          :data-testid="testIds.action.cancel"
          @click="onCancel"
        >
          {{ texts.actions.cancel }}
        </button>
      </div>
    </template>
  </form>
</template>

<script setup lang="ts">
import {
  LocalizedActivityDTO,
  LocalizedStudyProgramDTO,
} from "@/lib/eduConfigurationServiceClient";
import texts from "@/utils/texts";
import { computed, watch } from "vue";
import FormFieldStudyProgramPicker from "@/components/study-programs/study-program-picker/FormFieldStudyProgramPicker.vue";
import { useForm } from "vee-validate";
import * as yup from "yup";
import { testIds } from "@/utils/testing";
import { IconSize } from "@/components/common/icon/Icon.types";
import { Color } from "@/enums";
import Icon from "@/components/common/icon/Icon.vue";
import Card from "@/components/common/card/Card.vue";
import Checkbox from "@/components/common/checkbox/Checkbox.vue";
import Tag from "@/components/common/tag/Tag.vue";
import Divider from "@/components/common/divider/Divider.vue";

const props = withDefaults(
  defineProps<{
    studyPrograms: LocalizedStudyProgramDTO[];
    formValues: StudyProgramFilterFormValues;
    apply?: boolean;
    activity?: LocalizedActivityDTO;
  }>(),
  {
    apply: false,
    activity: undefined,
  },
);

const emit = defineEmits<{
  submit: [values: StudyProgramFilterFormValues];
  cancel: [];
}>();

const componentTexts = texts.navigationItems.mailing.steps.selectionDefinition;

export type StudyProgramFilterFormValues = {
  studyProgramIds: string[];
  activityIds: string[];
};

const form = useForm<StudyProgramFilterFormValues>({
  validationSchema: yup.object({
    activityIds: yup.array(yup.string()).required(),
    studyProgramIds: yup.array(yup.string()).required(),
  }),
});

const useActivityFilter = computed({
  get: () => form.values.activityIds.length > 0,
  set: (value) => {
    if (value && props.activity?.id) {
      form.setFieldValue("activityIds", [props.activity?.id]);
      form.setFieldValue("studyProgramIds", []);
    } else {
      form.setFieldValue("activityIds", []);
    }
  },
});

const onSubmit = () => {
  form.handleSubmit(() => {
    emit("submit", form.values);
  })();
};

const onCancel = async () => {
  form.resetForm({
    values: {
      studyProgramIds: props.formValues.studyProgramIds,
    },
  });
  emit("cancel");
};

form.setValues({
  activityIds: props.formValues.activityIds,
  studyProgramIds: props.formValues.studyProgramIds,
});

watch(form.values, () => {
  if (props.apply) {
    onSubmit();
  }
});
</script>
