<template>
  <SlideOver
    v-model:visible="visible"
    :title="texts.navigationItems.dashboard.selection.title"
    size="xl"
    :disabled="isDirty"
    @close="onClose"
  >
    <div class="flex h-full flex-col">
      <div class="h-full flex-1 px-6">
        <SelectionDefinition
          v-if="selectionDefinition"
          v-model="selectionDefinition"
          :context
          :exclude="[SelectionCriterionDtoType.IsRegisteredForSession]"
          class=""
        >
          <SelectionCountForDashboard :definition="selectionDefinition" />
        </SelectionDefinition>
      </div>
      <div class="flex gap-2 px-6 py-4">
        <div
          :class="
            submitButtonAccent &&
            'animate-quick-pulse rounded-md outline outline-4 outline-offset-2 outline-emerald-500/60 delay-150'
          "
        >
          <ButtonSubmit @click="onSubmit" />
        </div>
        <ButtonCancel @click="visible = false">
          {{ texts.actions.cancel }}
        </ButtonCancel>
      </div>
    </div>
  </SlideOver>
</template>

<script setup lang="ts">
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import { CriterionFieldContext } from "@/components/selection/SelectionCriterionForm.context";
import SelectionDefinition from "@/components/selection/SelectionDefinition.vue";
import {
  SelectionCriterionDtoType,
  SelectionDefinitionDto,
} from "@/lib/eduConfigurationServiceClient";
import selection from "@/store/context/selection.context";
import texts from "@/utils/texts";
import SelectionCountForDashboard from "@/views/dashboard/components/SelectionCountForDashboard.vue";
import { ref, watch } from "vue";

defineProps<{ context: CriterionFieldContext }>();

function deepCopySelectionDefinition() {
  if (!selection.definition) {
    return new SelectionDefinitionDto();
  }
  return SelectionDefinitionDto.fromJS(selection.definition.toJSON());
}

const visible = defineModel<boolean>("visible", { required: true });

const selectionDefinition = ref<SelectionDefinitionDto>();

const isDirty = ref(false);

watch(
  visible,
  (newValue) => {
    if (newValue) {
      selectionDefinition.value = deepCopySelectionDefinition();
    }
  },
  { immediate: true },
);

watch(selectionDefinition, (newValue) => {
  isDirty.value =
    JSON.stringify(selection.definition?.toJSON()) !==
    JSON.stringify(newValue?.toJSON());
});

const onSubmit = () => {
  selection.definition = selectionDefinition.value;
  isDirty.value = false;
  visible.value = false;
};

const submitButtonAccent = ref(false);
const onClose = () => {
  if (isDirty.value) {
    submitButtonAccent.value = true;
    setTimeout(() => {
      submitButtonAccent.value = false;
    }, 1200);
  }
};
</script>

<style scoped>
.animate-quick-pulse {
  animation: quick-pulse 0.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes quick-pulse {
  50% {
    opacity: 0.8;
  }
}
</style>
