<template>
  <FormFieldMultiCriterion
    :id
    :options
    :initialValue="
      criterion.targetAudienceIds ? [...criterion.targetAudienceIds] : undefined
    "
    :disabled="readonly || criterion.isReadOnly"
  />
</template>

<script setup lang="ts">
import { TypedCriterionFieldProps } from "@/components/selection/SelectionCriterionForm.types";
import { fields } from "@/utils/miscellaneous";
import { SelectionCriterionDto } from "@/lib/eduConfigurationServiceClient";
import FormFieldMultiCriterion from "@/components/selection/type/components/FormFieldMultiCriterion.vue";
import { computed } from "vue";

const props = defineProps<TypedCriterionFieldProps>();

const id = fields<SelectionCriterionDto>().targetAudienceIds;

const options = computed(() =>
  props.context.targetAudiences.map((audience) => ({
    id: audience.id,
    label: audience.name,
  })),
);
</script>
