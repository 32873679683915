<template>
  <Icon
    icon="add_circle"
    :size="size"
    :class="[
      'absolute -left-11',
      {
        '-left-[2.8rem]': size === IconSize.md,
        '-left-[3.05rem]': size === IconSize.lg,
      },
      isNew ? 'text-lavender-500' : 'text-deepteal-200',
    ]"
    filled
  />
</template>

<script setup lang="ts">
import { IconSize } from "@/components/common/icon/Icon.types";
import Icon from "@/components/common/icon/Icon.vue";
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    isNew?: boolean;
  }>(),
  {
    isNew: false,
  },
);

const size = computed(() => (props.isNew ? IconSize.lg : IconSize.md));
</script>
