<template>
  <form class="flex flex-col gap-6 sm:gap-8" @submit="submit">
    <div class="flex flex-col gap-4">
      <FormFieldSelectNative
        id="activityLocationId"
        :options="activityLocations"
        :label="texts.models.activityLocation.title"
        :disabled="activity.isOnline"
        :placeholder="
          activity.isOnline ? texts.models.activity.online : undefined
        "
      />
      <FormFieldDateTime
        id="visitDateTime"
        :label="componentTexts.addVisit.visitDateTime"
        :min="activity.startDateTime"
        :max="activity.endDateTime"
      />
    </div>
    <div class="flex flex-col gap-2 *:flex-1 lg:flex-row-reverse">
      <slot></slot>
    </div>
  </form>
</template>

<script setup lang="ts">
import FormFieldDateTime from "@/components/common/datetime/FormFieldDateTime.vue";
import FormFieldSelectNative from "@/components/common/select/FormFieldSelectNative.vue";
import { ActivityDTO } from "@/lib/eduConfigurationServiceClient";
import settings from "@/store/context/settings.context";
import texts from "@/utils/texts";
import { ContactMomentAddVisitFormValues } from "@/views/contacts/components/ContactMomentAddVisitForm.types";
import { DateTime } from "luxon";
import { useForm } from "vee-validate";
import { computed } from "vue";
import * as yup from "yup";

const componentTexts = texts.navigationItems.contact.contactMoments;

const props = defineProps<{
  activity: ActivityDTO;
}>();

const emit = defineEmits<{
  submit: [values: ContactMomentAddVisitFormValues];
  cancel: [];
}>();

const form = useForm<ContactMomentAddVisitFormValues>({
  validationSchema: yup.object({
    activityLocationId: yup
      .string()
      .test("required-when-not-online", texts.validation.required, (value) => {
        return props.activity.isOnline ? true : !!value;
      }),
    visitDateTime: yup
      .mixed<DateTime>()
      .isLaterThan(props.activity.startDateTime, texts.validation.outOfRange)
      .isEarlierThan(props.activity.endDateTime, texts.validation.outOfRange)
      .required(),
  }),
});

const activityLocations = computed(() => {
  if (props.activity.isOnline) {
    return [];
  }
  return settings.activityLocations
    .filter((l) => props.activity.locationIds.includes(l.id))
    .map((l) => ({
      label: l.name,
      value: l.id,
    }));
});

if (activityLocations.value.length > 0) {
  form.setFieldValue(
    "activityLocationId",
    activityLocations.value[0].value,
    false,
  );
}

form.setFieldValue("visitDateTime", props.activity.startDateTime, false);

const submit = form.handleSubmit(async (values) => {
  emit("submit", values);
});
</script>
