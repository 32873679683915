<template>
  <div class="flex flex-col gap-2">
    <div v-if="selectedOption" class="flex flex-wrap items-center gap-1.5">
      <Tag> {{ selectedOption.label }} </Tag>
      <button v-if="!disabled" type="button" @click="showCombobox = true">
        <Icon
          icon="edit"
          :size="IconSize.sm"
          :color="Color.Lavender"
          clickable
        />
      </button>
    </div>
    <SingleSelectCombobox
      v-if="showCombobox"
      v-model="id"
      :options="options"
      :formatOptionDisplayString
      :searchFilter
      :valid
      :color="Color.Lavender"
      hideTags
      :focus="focusCombobox"
      @focusout="onFocusOut"
    ></SingleSelectCombobox>
  </div>
</template>

<script
  setup
  lang="ts"
  generic="OptionType extends { id: string; label: string }"
>
import { computed, ref, watch } from "vue";
import { Color } from "@/enums";
import { IconSize } from "@/components/common/icon/Icon.types";
import Icon from "@/components/common/icon/Icon.vue";
import SingleSelectCombobox from "@/components/common/combobox/SingleSelectCombobox.vue";
import Tag from "@/components/common/tag/Tag.vue";

const props = withDefaults(
  defineProps<{
    options: OptionType[];
    searchFilter?: (option: OptionType, query: string) => boolean;
    formatOptionDisplayString?: (option: OptionType) => string;
    valid?: boolean;
    disabled?: boolean;
  }>(),
  {
    searchFilter: (option: OptionType, query: string) =>
      !!option.label?.toLowerCase().includes(query.toLowerCase()),
    formatOptionDisplayString: (option: OptionType) => option.label,
    valid: true,
    disabled: false,
  },
);

const id = defineModel<string | undefined>();

const selectedOption = computed(() =>
  props.options.find((option) => option.id === id.value),
);

const showCombobox = ref(false);
const focusCombobox = ref(false);

const onFocusOut = () => {
  focusCombobox.value = false;
  if (id.value) {
    showCombobox.value = false;
  }
};

watch(
  id,
  () => {
    if (!id.value) {
      showCombobox.value = true;
      focusCombobox.value = true;
    }
  },
  { immediate: true },
);
</script>
