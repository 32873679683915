<template>
  <select
    v-model="modelValue"
    :class="[
      'w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 text-base ring-0 sm:text-sm/6',
      !!modelValue ? 'text-gray-900' : 'text-gray-400',
      getFocusRingColor(valid ? color : Color.Alert),
      getFocusBorderColor(valid ? color : Color.Alert),
      valid ? 'border-gray-300' : 'border-alert-300',
    ]"
    :disabled
  >
    <slot></slot>
    <option
      v-if="placeholder"
      :value="undefined"
      disabled
      class="text-gray-300"
    >
      {{ placeholder }}
    </option>
    <option
      v-for="option in options"
      :key="option.value"
      :value="option.value"
      class="text-gray-900"
    >
      {{ option.label ?? option.value }}
    </option>
  </select>
</template>

<script setup lang="ts">
import { Color } from "@/enums";
import { getFocusBorderColor, getFocusRingColor } from "@/utils/color";

withDefaults(
  defineProps<{
    options: { value: string; label?: string }[];
    color?: Color;
    placeholder?: string;
    disabled?: boolean;
    valid?: boolean;
  }>(),
  {
    color: Color.Emerald,
    placeholder: undefined,
    disabled: false,
    valid: true,
  },
);
const modelValue = defineModel<string>();
</script>
