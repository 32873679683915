<template>
  <div class="flex flex-col gap-2">
    <div
      v-if="slots.filters || slots.header"
      class="flex flex-col gap-2 sm:flex-row-reverse sm:items-center sm:justify-between"
    >
      <div class="flex flex-col gap-1.5 sm:flex-row">
        <slot v-if="slots.filters" name="filters"></slot>
      </div>
      <div class="flex-1">
        <slot v-if="slots.header" name="header"></slot>
      </div>
    </div>

    <div v-if="slots.criteria" class="relative ml-6 flex gap-8">
      <div class="-mb-2 -mt-2 border-l border-dashed border-l-gray-200"></div>
      <div class="flex flex-1 flex-col gap-2" :data-testid="testIds.items">
        <slot name="criteria"></slot>
      </div>
    </div>

    <div v-if="slots.actions" class="relative ml-6 flex gap-8">
      <div class="mb-4 border-l border-dashed border-l-gray-200"></div>
      <div class="flex flex-1 flex-col gap-2">
        <slot name="actions"></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { testIds } from "@/utils/testing";

const slots = defineSlots<{
  header: [];
  criteria: [];
  actions: [];
  filters: [];
}>();
</script>
